import React from 'react';
import { Dialog } from '@material-ui/core';
import { AddNewFirmwareVersion, AddNewFirmwareVersionProps } from '.';

interface AddUserToOrganizationModalProps extends AddNewFirmwareVersionProps {
  open: boolean;
}

export const NewFirmwareVersionModal = (
  props: AddUserToOrganizationModalProps
) => {
  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        aria-labelledby="new-firmware-dialog"
        maxWidth="sm"
        fullWidth
      >
        <AddNewFirmwareVersion
          onCancel={props.onCancel}
          onSaved={props.onSaved}
        />
      </Dialog>
    </React.Fragment>
  );
};

