import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  bodyRow: {
    '& > *': { borderBottom: 'unset' },
  },
  cameraNameCell: {
    // width: '100%',
    maxWidth: '100%',
    minWidth: '100%',
    padding: '12px 16px',
    [theme.breakpoints.down('sm')]: {
      width: '65%',

      order: '1',
    },
  },
  imei: {
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'row',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: theme.palette.grey[700],
  },
  collections: {
    fontSize: '12px',

    color: theme.palette.grey[700],

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  cameraDetailPanel: {
    padding: theme.spacing(3),
  },
  collectionsTitle: {
    display: 'none',
    fontSize: '12px',
    color: theme.palette.grey[700],
  },
  collectionsTitleCell: {
    padding: '12px 16px',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  statusIcons: {
    display: 'flex',
    margin: '15px 0',

    [theme.breakpoints.up('sm')]: {
      margin: '0 0 0 auto',
    },
  },
  deleteButtonCell: {
    padding: '12px 16px',
    [theme.breakpoints.down('sm')]: {
      flex: '1 0 100%',
      order: '3',
      padding: '16px',
      alignItems: 'center',
    },
  },
  collapseButtonCell: {
    padding: '12px 16px',

    [theme.breakpoints.down('sm')]: {
      flex: '1 0 100%',
      padding: '16px',
      order: '4',
      alignItems: 'center',
    },
  },
}));

