import React, { useState, useEffect, Suspense } from 'react';
import { CircularProgress } from '@material-ui/core';
import { ConfirmDialogModal } from '../../../components/confirm-dialog-modal';
import { Panel } from '../../../components/panel/panel';
import { OrganizationDetailPage } from '../../../api/isc-api';

import { PanelCardHeader } from '../../../components/panel/panel-card-header';
import CameraRow from './camera-row';

import { OrganizationDetailsAPI } from '../../../api/organization-details';

const AttachCameraToOrganizationModal = React.lazy(
  () =>
    import(
      '../../../components/attach-camera-to-organization/attach-camera-to-organization-modal'
    )
);

type CameraPanelProps = {
  isLoading: boolean;
  organization: OrganizationDetailPage;
  setOrganization: React.Dispatch<React.SetStateAction<OrganizationDetailPage>>;
  setShowAlert: React.Dispatch<React.SetStateAction<any>>;
  getOrganization: (value?: unknown) => Promise<void | OrganizationDetailPage>;
};

export const CameraPanel = (props: CameraPanelProps) => {
  const { organization, setShowAlert, setOrganization } = props;

  const [filter, setFilter] = useState('');
  const [filteredCameras, setFilteredCameras] = useState([]);

  const [isPendingAddCameras, setIsPendingAddCameras] = useState(false);
  const [isPendingDelete, setIsPendingDelete] = useState(false);

  const handleCloseCameraModal = async () => {
    setIsPendingAddCameras(false);
  };

  const handleRemoveCamera = async (camera: any) => {
    if (camera) {
      await OrganizationDetailsAPI.deleteCamera(
        organization.organizationId,
        camera.camera.cameraId
      );
      const filterCamera = (el: any) => {
        return el?.camera.cameraId.toString() !== camera.camera.cameraId;
      };
      let newCameras = organization.cameras.filter(filterCamera);
      setOrganization({ ...organization, cameras: newCameras });
      setIsPendingDelete(false);
    }
  };
  const getOrganizationUpdate = async () => {
    try {
      const org = await props.getOrganization();
      if (org) {
        setOrganization({ ...organization, cameras: org.cameras });
      }
    } catch (err) {
      setShowAlert({ type: 'error', value: err.message });
    }
  };
  const handleAddCameras = () => {
    getOrganizationUpdate();
    setIsPendingAddCameras(false);
  };

  const handleFilterChange = async (filter: string) => {
    setFilter(filter ? filter.toLowerCase() : '');
  };

  useEffect(() => {
    if (organization.cameras && !filter) {
      setFilteredCameras(organization.cameras);
    }
  });

  useEffect(() => {
    const query = new RegExp(filter, 'igm');
    const filterCamera = (el: any) => {
      return (
        el?.camera.cameraName.match(query) ||
        el?.camera.imei.toString().match(query)
      );
    };

    setFilteredCameras(organization.cameras.filter(filterCamera));
  }, [filter]);

  const onAddNew =
    organization.cameras.length < 100 && (() => setIsPendingAddCameras(true));

  return (
    <>
      <Panel
        onAdd={onAddNew}
        addButtonText="Add Cameras"
        panelHeader={
          <PanelCardHeader
            title="Camera Access"
            buttonText="Add"
            placeholderText="Search cameras"
            onAddNew={onAddNew}
            onFilterChange={handleFilterChange}
            darkColors
          />
        }
        columnTitles={['Name', 'Collections', '', '']}
        filteredItems={filteredCameras}
        updatedItems={organization.cameras}
      >
        {filteredCameras.map((camera, index) => {
          return (
            <CameraRow
              camera={camera}
              isLoading={props.isLoading}
              setIsPendingDelete={setIsPendingDelete}
              organizationId={organization.organizationId}
            />
          );
        })}
      </Panel>
      <Suspense fallback={<CircularProgress />}>
        <AttachCameraToOrganizationModal
          open={isPendingAddCameras}
          handleSave={handleAddCameras}
          handleCancel={handleCloseCameraModal}
          organization={organization}
        />
      </Suspense>
      <ConfirmDialogModal
        open={isPendingDelete}
        value={isPendingDelete}
        handleConfirm={handleRemoveCamera}
        handleClose={() => setIsPendingDelete(false)}
        dialogTitle="Remove Camera from Organization"
        message="Are you sure you want to remove this camera from the organization?"
        confirmText="Remove"
      />
    </>
  );
};

