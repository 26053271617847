
import { UsersAPI } from '../api/users';
import { useApi } from '../hooks';
import { AdminUserGetResponse } from '../api/isc-api';

export const useAdminUserCheck = (): [AdminUserGetResponse, boolean] => {
  const [isAdminUser, , isLoading] = useApi<AdminUserGetResponse>(() => 
  	UsersAPI.isAdmin()
  );

  return [isAdminUser,  isLoading];
};
