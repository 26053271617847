import React from 'react';

import { OrganizationDetailPageFeatures, UserDetails } from '../../api/isc-api';

import { AddOrganizationAdmin } from './AddOrganizationAdmin';
import { CreateNewOrganization } from './CreateNewOrganization';
import { SetOrganizationFeatures } from './SetOrganizationFeatures';
import { LoadingAddNewOrganization } from './LoadingOrganization';

type OrganizationAddProps = {
  onCancel: () => void;
  onSaved: (id?: string) => void;
};

export const AddOrganization = (props: OrganizationAddProps) => {
  const [step, setStep] = React.useState(1);
  const [organization, setOrganization] = React.useState(null);
  const [users, setUsers] = React.useState<UserDetails[]>([]);
  const [features, setFeatures] =
    React.useState<OrganizationDetailPageFeatures>(null);

  const handleCreateNewOrganizationNext = (organization: string) => {
    setOrganization(organization);
    setStep(2);
  };

  const handleAddOrganizationAdminNext = (userDetails: UserDetails[]) => {
    setUsers(userDetails);
    setStep(3);
  };

  const handleSaveOrganization = (features: OrganizationDetailPageFeatures) => {
    setFeatures(features);
    setStep(4);
  };

  switch (step) {
    case 1:
      return (
        <CreateNewOrganization
          organization={organization || ''}
          onCancel={props.onCancel}
          onNext={handleCreateNewOrganizationNext}
        />
      );
    case 2:
      return (
        <AddOrganizationAdmin
          onCancel={props.onCancel}
          onNext={handleAddOrganizationAdminNext}
        />
      );
    case 3:
      return (
        <SetOrganizationFeatures
          handleSave={handleSaveOrganization}
          onCancel={props.onCancel}
        />
      );
    case 4:
      return (
        <LoadingAddNewOrganization
          onSaved={props.onSaved}
          updateObject={{
            name: organization,
            users,
            features,
          }}
        />
      );
  }
};

