import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    // maxWidth: "265px",
    padding: '0 8px 0 8px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  pinnedCamerasHeading: {
    marginLeft: '3.5em',
  },
  hasClick: {
    cursor: 'pointer',
  },
  cardWrapper: {
    width: '100%',
    minWidth: '200px',
    maxWidth: '200px',
    margin: '0',
    fontSize: '1em',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    border: '0px',
    boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.35)',
    padding: 0,
    // iPhone SE
    [theme.breakpoints.down(400)]: {
      minWidth: '200px',
      width: 'calc(100vw - 40px)',
    },
  },
  card: {
    display: 'inline-block',
    width: 'calc(25% - 10px)',
    margin: '5px',
    border: '1px solid #000',
    boxSizing: 'border-box',
  },
  cardMediaWrapper: {
    position: 'relative',
  },
  photoContainer: {
    height: 200,
  },
  timestamp: {
    fontSize: '10px',
    color: '#fff',
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    bottom: 0,
    left: 0,
    padding: '0.5em',
    margin: '5px',
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    paddingBottom: '16px !important',
    '*': {
      lineHeight: '1.1 !important',
    },
    '& *:hover, & *:focus, & *:active': {
      textDecoration: 'none !important',
    },
  },
  metaContainer: {
    maxWidth: '65%',
  },
  iconsContainer: {
    display: 'flex',
    marginLeft: 'auto',
    paddingLeft: theme.spacing(2),
  },
  cameraName: {
    textTransform: 'uppercase',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 700,
    fontSize: '1.3em',
    lineHeight: 1,

    '&:hover, &:focus': {
      textDecoration: 'none',
    },
  },
  cameraCollectionName: {
    color: theme.palette.grey[600],
    fontSize: '1em',
  },
}));
