import React from 'react';
import {
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useStyles } from './useStyles';
import { EmptyFallback } from '../empty-fallback';
import clsx from 'clsx';

type PanelProps = {
  panelHeader: React.ReactNode;
  columnTitles: string[];
  filteredItems: any;
  children: React.ReactNode;
  updatedItems: any;
  onAdd?: () => void;
  addButtonText?: string;
  emptyComponent?: React.ReactNode;
  emptyText?: string;
  useCardWrapper?: boolean;
};
type PanelBodyRowProps = {
  children: React.ReactNode;
  className?: any;
};
type PanelBodyCellProps = {
  name?: string;
  title?: string;
  index: number;
  length: number;
  disabled?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
};

export const Panel = (props: PanelProps) => {
  const classes = useStyles(props);

  const getFallback = () => {
    if (props.emptyComponent) {
      return props.emptyComponent;
    }

    // TODO: This is an artifact of an older Component API where panel was used only for
    // cameras. Every caller should update and this should be removed.
    const legacyFallbackText = props.updatedItems.length
      ? 'No cameras match the search parameters.'
      : 'This organization has no cameras assigned';

    return (
      <EmptyFallback
        buttonText={props.addButtonText || 'Add New'}
        onAction={props.onAdd}
        text={props.emptyText || legacyFallbackText}
      />
    );
  };

  const getBody = () => {
    return (
      <TableContainer
        style={{
          minHeight: 244,
          maxHeight: 460,
          width: '100%',
        }}
      >
        <Table stickyHeader className={classes.panelTable}>
          <TableHead>
            <TableRow className={classes.headerRow}>
              {props.columnTitles.map((columnTitle, index) => {
                return (
                  <TableCell key={index} className={classes.headerCell}>
                    <Typography className={classes.columnTitle}>
                      {columnTitle}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>{props.children}</TableBody>
        </Table>
      </TableContainer>
    );
  };

  const panelContent = (
    <>
      <div className={classes.panelHeader}>{props.panelHeader}</div>

      {props.filteredItems.length === 0 ? getFallback() : getBody()}
    </>
  );

  if (props.useCardWrapper === false) {
    return <>{panelContent}</>;
  }

  return <Card className={classes.card}>{panelContent}</Card>;
};

Panel.BodyRow = (props: PanelBodyRowProps) => {
  const classes = useStyles(props);

  return (
    <TableRow className={clsx(classes.bodyRow, props.className)}>
      {props.children}
    </TableRow>
  );
};

Panel.BodyCell = (props: PanelBodyCellProps) => {
  const classes = useStyles(props);

  return (
    <TableCell
      className={`${classes[props.name]} ${
        props.index === props.length - 1 && classes.noBottomBorder
      }`}
    >
      {props.children}
    </TableCell>
  );
};

Panel.BodyCellToggle = (props: PanelBodyCellProps) => {
  const classes = useStyles(props);

  return (
    <TableCell
      className={`${classes.tcellToggle} ${
        props.index === props.length - 1 && classes.noBottomBorder
      }`}
    >
      <Typography className={classes.tcellToggleText}>{props.title}</Typography>
      {props.children}
    </TableCell>
  );
};

Panel.BodyCellDelete = (props: PanelBodyCellProps) => {
  const classes = useStyles(props);

  return (
    <TableCell
      className={`${classes.tcellDelete} ${
        props.index === props.length - 1 && classes.noBottomBorder
      }`}
    >
      <IconButton disabled={props.disabled} onClick={props.onClick}>
        <Delete />
      </IconButton>
    </TableCell>
  );
};
