import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { Logout, UserPortal } from '../../../../components/mui-icons';

import List from '@material-ui/core/List';

//import Typography from '@material-ui/core/Typography';

import MenuIcon from '@material-ui/icons/Menu';

import clsx from 'clsx';
import { useHistory } from 'react-router';
import logoImage from '../../../../images/spotlight-text-white.svg';

import { useStyles } from './useStyles';
import { MainMenuItem } from './MainMenuItem';

import AppContext, { AppContextType } from '../../../../context';
import { Logo } from '../logo';

export type DesktopMenuProps = {
  drawerWidth: number;
  drawerOpen: boolean;

  onToggleDrawer: () => void;
  onLogout: () => void;
};

export const DesktopMenu = (props: DesktopMenuProps) => {
  const classes = useStyles(props);
  const context = useContext<AppContextType>(AppContext);
  const history = useHistory();

  const goHome = () => {
    history.push('/');
  };

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, props.drawerOpen && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <div className={classes.controlBar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={props.onToggleDrawer}
            className={clsx(
              classes.menuButton,
              props.drawerOpen && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.toolbarLogo} onClick={goHome}>
            <Logo light />
          </div>
        </div>

        <List className={classes.menuList}>
          <MainMenuItem
            href="/"
            primary="Organizations"
            classes={classes}
            exact={true}
          ></MainMenuItem>
          <MainMenuItem
            href="/firmware"
            primary="Firmware"
            classes={classes}
          ></MainMenuItem>
        </List>

        <div className={classes.iconBar}>
          <IconButton
            className={classes.userButton}
            color="inherit"
            onClick={props.onLogout}
            title="Log Out"
          >
            <Logout />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};
