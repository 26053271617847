import React from 'react';
import {
  CollectionCameraDetailSearchGetResponseDataCameras,
  OrganizationDetailPage,
} from '../../api/isc-api';

import { AddCameraToOrganization } from './add-cameras';
import { ConfirmAddCameras } from './confirm-add-cameras';
import { LoadingAttachCameraToOrganization } from './loading-attach-camera-to-organization';
import { AddCameraToOrganizationSuccess } from './successful-save';

export interface AttachCameraToOrganizationProps {
  organization: OrganizationDetailPage;
  handleCancel: () => void;
  handleSave: () => void;
}

export const AttachCameraToOrganization = (
  props: AttachCameraToOrganizationProps
) => {
  const [step, setStep] = React.useState(1);
  const [cameras, setCameras] = React.useState<
    CollectionCameraDetailSearchGetResponseDataCameras[]
  >([]);

  const handleAddOrganizationAdminNext = (
    newCameras: CollectionCameraDetailSearchGetResponseDataCameras[]
  ) => {
    setCameras(newCameras);
    setStep(2);
  };

  const handleConfirmationNext = () => {
    // loading
    setStep(3);
  };

  const handleSuccessfulSave = () => {
    setStep(4);
  };

  switch (step) {
    case 1:
      return (
        <AddCameraToOrganization
          onCancel={props.handleCancel}
          onNext={handleAddOrganizationAdminNext}
          skipCameras={props.organization?.cameras}
        />
      );
    case 2:
      return (
        <ConfirmAddCameras
          organizationName={props.organization?.organizationName}
          onNext={handleConfirmationNext}
          onCancel={props.handleCancel}
          cameras={cameras}
        />
      );
    case 3:
      return (
        <LoadingAttachCameraToOrganization
          organizationId={props.organization?.organizationId}
          onSaved={handleSuccessfulSave}
          updateObject={{
            cameraIds: cameras.map((camera) => camera.camera.cameraId),
          }}
        />
      );
    case 4:
      return (
        <AddCameraToOrganizationSuccess
          cameras={cameras}
          organizationName={props.organization?.organizationName}
          onDone={props.handleSave}
        />
      );
  }
};

