import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  cardHeader: {
    [theme.breakpoints.down('xs')]: {
      '& .MuiCardHeader-content': {
        overflow: 'hidden',
      },
      '& .MuiTypography-root': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  tabWrapper: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(2.5),
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  avatarCell: {
    padding: '12px 16px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  userNameCell: {
    padding: '0px 16px',
    maxWidth: '100%',
    minWidth: '100%',

    [theme.breakpoints.down('sm')]: {
      padding: '12px 16px',
      width: '65%',
      order: '1',
    },
  },
  iconButtonCell: {
    padding: '12px 12px',

    [theme.breakpoints.down('sm')]: {
      flex: '1 0 100%',
      padding: '12px 0px',
      order: '3',
      alignItems: 'center',
    },
  },
  userName: {
    display: 'flex',
    flexDirection: 'row',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

