import React, { useEffect, useState } from 'react';

import { useDebouncedValue } from '../hooks/useDebounceValue';

import { CircularProgress, makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

type DebounceAutocompleteProps = {
  value?: string;
  onSubmit?: (query: string) => void;
  onSearchInput?: (query: string) => void;
  placeholder?: string;
  debounceWait?: number;
  showSearchButton?: boolean;
  classNames?: {
    searchBox?: string;
    searchInput?: string;
    searchButton?: string;
    searchIcon?: string;
  };
  options: any[];
  loading: boolean;
  error?: string;
  characterMin?: number;
  handleSelectedOptions: (value: any) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
};
const useStyles = makeStyles((theme) => ({
  searchBox: {
    display: 'flex',
  },

  searchInput: {
    border: 'none',
    flex: '1 1 auto',

    '&:focus-visible': {
      outline: 'none',
    },
  },

  searchIcon: {
    color: theme.palette.common.black,
  },

  searchButton: {
    background: 'transparent',
    border: 'none',
  },
}));

export const DebounceAutocomplete = ({
  value = '',
  placeholder = 'Search',
  debounceWait = 350,
  onSubmit,
  onSearchInput,
  characterMin = 3,
  classNames,
  options,
  error,
  handleSelectedOptions,
  loading,
  open,
  setOpen,
}: DebounceAutocompleteProps) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(value);
  const debouncedInput = useDebouncedValue(inputValue, debounceWait);

  const onChange = (value: any) => {
    if (value.length >= characterMin) {
      setInputValue(value);
    }
  };

  // Support passing down a value from parent component
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  // Broadcast input text changes if callback exists
  useEffect(() => {
    onSearchInput && onSearchInput(debouncedInput);
  }, [debouncedInput]);

  // On button click
  const handleSubmit = (e) => {
    e.preventDefault();

    // METRIE-320: Make sure the input value catches up before submitting
    if (onSubmit) {
      setTimeout(() => {
        onSubmit(debouncedInput);
      }, debounceWait);
    }
  };

  return (
    <Autocomplete
      fullWidth
      multiple
      id="add-user"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onSubmit={handleSubmit}
      filterSelectedOptions
      options={options}
      loading={loading}
      onChange={(event, value) => {
        handleSelectedOptions(value);
      }}
      onInputChange={(event, newInputValue) => {
        onChange(newInputValue);
      }}
      getOptionLabel={(option) => `${option.camera.imei}`}
      renderInput={(params) => (
        <TextField
          {...params}
          error={Boolean(error)}
          helperText={error}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

