import React from 'react';

import { Typography } from '@material-ui/core';

import darklogo from '../../../images/spotlight-text-black.svg';
import whitelogo from '../../../images/spotlight-text-white.svg';
import { useStyles } from './useStyles';
import clsx from 'clsx';
type LogoProps = {
  image?: any;
  light?: boolean;
  className?: any;
  login?: boolean;
};
export const Logo = ({ image, login, light, className }: LogoProps) => {
  const classes = useStyles();

  return (
    <div className={classes.logoContainer}>
      <img
        src={image ? image : light ? whitelogo : darklogo}
        alt="ISC logo"
        className={clsx(
          login ? classes.loginLogo : classes.logo,
          className && className
        )}
      />
      <div
        className={clsx(
          login ? classes.loginAdminPortalText : classes.adminPortalText
        )}
      >
        {login ? null : (
          <Typography
            variant={'h3'}
            className={clsx(classes.text, light && classes.light)}
            noWrap
          >
            Admin Portal
          </Typography>
        )}
      </div>
    </div>
  );
};

