import React from 'react';
import { Dialog } from '@material-ui/core';
import { AddUserToOrganization, OrganizationAddProps } from '.';

interface AddUserToOrganizationModalProps extends OrganizationAddProps {
  open: boolean;
}

export const AddUserToOrganizationModal = (
  props: AddUserToOrganizationModalProps
) => {
  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        aria-labelledby="creature-user-form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <AddUserToOrganization
          updateOrganization={props.updateOrganization}
          organization={props.organization}
          handleCancel={props.handleCancel}
          handleSave={props.handleSave}
        />
      </Dialog>
    </React.Fragment>
  );
};

