import React, { useCallback, useEffect, useState } from 'react';
import {

	Grid,
  LinearProgress,
  makeStyles,
	Typography,


} from '@material-ui/core';
import { useApi } from '../hooks';
import { useStyles } from "../pages/login/useStyles";
import { UsersAPI } from '../api/users';
import { useAppContext } from '../pages/app/useAppContext';
import { useAdminUserCheck } from './useAdminUser';



type AuthorizingUserProps = {
	handleAdminUser: () => void;
	handleUnAuthorizedUser: () => void;
};

export const AuthorizingUser = (props: AuthorizingUserProps) => {
  const classes = useStyles();
  const [value, setValue] = useState();
	const context = useAppContext();
	const [ isAdminUser, isLookingUp ] = useAdminUserCheck()

	useEffect(() => {
		console.log({ isAdminUser, context })
		if (isLookingUp){
			return;
		}
		if (!!isAdminUser.isAdminUser){
			
			props.handleAdminUser();
		} else {
props.handleUnAuthorizedUser();
		}

	}, [isAdminUser, isLookingUp])

	if (isLookingUp){
	  return (
    <Grid container spacing={2}>
              <Grid item xs={12} className={classes.signInContainer}>
                <Typography variant="subtitle1">
                  Authenticating&hellip;
                </Typography>
                <div className={classes.progress}>
                  <LinearProgress />
                </div>
              </Grid>
            </Grid>
  );	
	} else {
		 return <h1>IS IT ADMIN ? {isAdminUser.toString()}</h1>
	}

};

