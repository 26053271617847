import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { ModalStep } from '../add-organization/step';
import { useStyles } from './useStyles';
export type FirmwareForm = {
  firmwareVersion: string;
  releaseNotes: string;
};
type NewFirmwareVersionFormProps = {
  onCancel: () => void;
  onNext: (details: FirmwareForm) => void;
  title: string;
  subtitle: string;
  file: any;
};

export const NewFirmwareVersionForm = (props: NewFirmwareVersionFormProps) => {
  const [error, setError] = useState(undefined);

  const classes = useStyles();
  const [firmwareForm, setFirmwareForm] = useState<FirmwareForm>({
    firmwareVersion: '',
    releaseNotes: '',
  });
  const ValidateForm = () => {
    let error;
    if (firmwareForm.releaseNotes.length > 500) {
      error = {
        ...error,
        releaseNotes: 'Maximum length for release notes is 500 characters.',
      };
    } else if (firmwareForm.releaseNotes.length === 0) {
      error = {
        ...error,
        releaseNotes:
          "Please enter some notes to describe what's new in this firmware release.",
      };
    }
    if (firmwareForm.firmwareVersion.length === 0) {
      error = {
        ...error,
        firmwareVersion: 'Please enter a Version Number',
      };
    }
    return error;
  };
  const handleNext = () => {
    const error = ValidateForm();
    if (!error) {
      props.onNext(firmwareForm);
    } else {
      setError(error);
    }
  };
  const handleFormInputs = e => {
    setFirmwareForm({ ...firmwareForm, [e.target.name]: e.target.value });
  };
  const getFileSize = (fileSizeBytes: number) => {
    let fileSizeMB = fileSizeBytes / 1024 ** 2;
    return `${fileSizeMB.toFixed(2)} MB`;
  };

  return (
    <ModalStep
      title={props.title}
      subtitle={props.subtitle}
      onCancel={props.onCancel}
      actions={
        <React.Fragment>
          <Button onClick={props.onCancel} size="small" tabIndex="-1">
            Cancel
          </Button>
          <Button type="submit" color="primary" size="small">
            {'Finish'}
          </Button>
        </React.Fragment>
      }
      onSubmit={handleNext}
    >
      <div>
        <p>
          <strong> Selected File:</strong>{' '}
          {`${props.file.name} (${getFileSize(props.file.size)})`}
        </p>
        <form>
          <div className={classes.firmwareFormVersionNumber}>
            <p className={classes.firmwareFormLabel}>
              <strong>Version Number: </strong>
            </p>
            <TextField
              label={''}
              variant={'outlined'}
              className={classes.firmwareVersionInput}
              value={firmwareForm.firmwareVersion}
              name={'firmwareVersion'}
              fullWidth
              error={!!error?.firmwareVersion}
              helperText={!!error?.firmwareVersion && error.firmwareVersion}
              onChange={handleFormInputs}
            />
          </div>
          <div>
            <p className={classes.firmwareFormLabel}>
              <strong> Release Notes: </strong>
            </p>
          </div>
          <TextField
            multiline={true}
            name={'releaseNotes'}
            rows={5}
            variant={'outlined'}
            fullWidth
            value={firmwareForm.releaseNotes}
            error={!!error?.releaseNotes}
            helperText={!!error?.releaseNotes && error.releaseNotes}
            onChange={handleFormInputs}
          />
        </form>
      </div>
    </ModalStep>
  );
};

