import React from 'react';

import List from '@material-ui/core/List';
import { Logout, Users } from '../../../../components/mui-icons';

import { SideMenuItem } from './SideMenuItem';

import { useStyles } from './useStyles';
import { CameraAlt, Home } from '@material-ui/icons';

type MobileMenuProps = {
  onLogout: () => void;
  setOpen: (value: React.SetStateAction<boolean>) => void;
};

export const MobileMenu = ({ onLogout, setOpen }: MobileMenuProps) => {
  const classes = useStyles();

  return (
    <List>
      <SideMenuItem
        href="/"
        icon={<Home />}
        primary="Organizations"
        classes={classes.navLink}
        setOpen={setOpen}
      />
      <SideMenuItem
        href="/firmware"
        icon={<CameraAlt />}
        primary="Firmware"
        classes={classes.navLink}
        setOpen={setOpen}
      />

      <SideMenuItem
        icon={<Logout />}
        primary={'Log out'}
        onClick={onLogout}
        classes={classes.navLink}
        setOpen={setOpen}
      />
    </List>
  );
};
