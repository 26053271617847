import { SessionInfo, SessionUser } from '../context';
import { ApiException } from './lib/domain';
import {
  getAuthOptions,
  processResponse,
} from './lib/helpers';
import { Auth } from 'aws-amplify';

import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';

import { ENABLE_DEV_CONSOLE } from '../util/globals';
const authURL = process.env.API_URL + '/app/auth';

export type CodeInfo = {
  name: string;
  phone: string;
};

export type ActivateRequest = {
  activationCode: string;
  password: string;
  challengeId: string;
  challengeCode: string;
};

export type ResetCodeRequest = {
  email: string;
  method: 'sms' | 'email';
};

export type ResetCodeResponse = {
  challengeId: string;
  destination: string;
};

const session = async (): Promise<SessionInfo> => {
  await getAuthOptions();

  const cognitoSession: CognitoUserSession = await Auth.currentSession();
  const user: { [id: string]: any } = cognitoSession
    .getIdToken()
    .decodePayload();

  const sessionInfo: SessionInfo = {
    user: user as SessionUser,
  };

  return sessionInfo;
};

let accessLogCount = 0;

export const AuthAPI = {
  getAuthOptions,
  session,
  loginOidc: async (user: CognitoUser): Promise<SessionInfo> => {
    const accessToken = user
      .getSignInUserSession()
      .getAccessToken()
      .getJwtToken();
  
    if (accessToken) {
  
        if (ENABLE_DEV_CONSOLE && accessLogCount++ === 0) {
          console.log('AccessToken:');
          console.log(accessToken);
        }

        return await session();
				
      
    } else {
      throw new ApiException(400, 'No Access token provided');
    }
  },

  codeInfo: async (code: string): Promise<{ info: CodeInfo }> => {
    const response = await fetch(authURL + '/code/' + code);
    return processResponse(response);
  },
};
