import {
  Avatar,
  CircularProgress,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { Suspense, useEffect, useState } from 'react';
import { useStyles } from './useStyles';
import { Panel } from '../../../components/panel/panel';
import { OrganizationDetailPageUsers, UserDetails } from '../../../api/isc-api';
import { Delete, OpenInBrowser, Person } from '@material-ui/icons';
import { PanelCardHeader } from '../../../components/panel/panel-card-header';
import { AddUserToOrganizationModal } from '../../../components/add-user-to-organization/create-user-modal';
import { ConfirmDialogModal } from '../../../components/confirm-dialog-modal';

export const UsersPanel = ({
  organization,
  updateOrganization,
  setOrganization,
  setShowAlert,
  isLoading,
}) => {
  const portalUrl = process.env.PUBLIC_PORTAL_URL;
  const classes = useStyles();
  const [filter, setFilter] = useState('');
  const [isPendingAddToUserGroup, setIsPendingAddToUserGroup] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState<
    OrganizationDetailPageUsers[]
  >([]);
  const [isPendingDelete, setIsPendingDelete] = useState(null);

  useEffect(() => {
    setFilteredUsers(organization.users);
  }, [organization.users]);

  const handleRemoveUser = async (user: any) => {
    if (user) {
      // await updateOrganization
      const filterCamera = (el: any) => {
        return el?.userId.toString() !== user.userId;
      };
      let newUsers = organization.users.filter(filterCamera);
      const body = {
        organizationName: organization.organizationName,
        features: organization.features,
        users: newUsers.map(user => ({
          userId: user.userId,
        })),
      };
      try {
        await updateOrganization({ id: organization.organizationId, body });
        setOrganization({ ...organization, users: newUsers });
        setIsPendingDelete(false);
      } catch (err) {
        setShowAlert({
          type: 'error',
          message: 'Something went wrong deleting this user',
        });
      }
    }
  };

  const handleFilterChange = async (filter: string) => {
    setFilter(filter ? filter.toLowerCase() : '');
  };
  const handleSave = (users: UserDetails[]) => {
    setIsPendingAddToUserGroup(false);
    setOrganization({ ...organization, users });
  };
  useEffect(() => {
    const filterUser = (el: OrganizationDetailPageUsers) => {
      return el.name.toLowerCase().indexOf(filter) > -1;
    };
    setFilteredUsers(organization.users.filter(filterUser));
  }, [filter]);

  return (
    <div className={classes.tabWrapper}>
      <Panel
        emptyText={
          !!filter
            ? 'There are no results for your search'
            : 'No users exist, add the first one below:'
        }
        onAdd={() => setIsPendingAddToUserGroup(true)}
        addButtonText="Add Users"
        panelHeader={
          <PanelCardHeader
            title="Users"
            buttonText="Add"
            placeholderText="Search users"
            onAddNew={() => setIsPendingAddToUserGroup(true)}
            onFilterChange={handleFilterChange}
            darkColors
          />
        }
        columnTitles={['', 'Name', '', '']}
        filteredItems={filteredUsers}
        updatedItems={organization.users}
      >
        {filteredUsers.map((user, index) => {
          return (
            <TableRow key={user.userId}>
              <TableCell className={classes.avatarCell}>
                <Avatar>
                  <Person />
                </Avatar>
              </TableCell>
              <TableCell className={classes.userNameCell}>
                <Typography component="h3" className={classes.userName}>
                  {user.name}
                </Typography>
              </TableCell>
              <TableCell
                className={classes.iconButtonCell}
                style={{ padding: 0 }}
              >
                <IconButton
                  disabled={isLoading}
                  onClick={() => setIsPendingDelete(user)}
                >
                  <Delete />
                </IconButton>
              </TableCell>{' '}
              <TableCell className={classes.iconButtonCell}>
                <a
                  href={`${portalUrl}/user/${user.userId}/${organization.organizationId}`}
                  target="_blank"
                >
                  <IconButton edge="end" aria-label="delete">
                    <OpenInBrowser />
                  </IconButton>
                </a>
              </TableCell>
            </TableRow>
          );
        })}
      </Panel>
      <Suspense fallback={<CircularProgress />}>
        <AddUserToOrganizationModal
          handleSave={handleSave}
          updateOrganization={updateOrganization}
          handleCancel={() => setIsPendingAddToUserGroup(false)}
          open={isPendingAddToUserGroup}
          organization={organization}
        />
      </Suspense>
      <ConfirmDialogModal
        open={isPendingDelete}
        value={isPendingDelete}
        handleConfirm={handleRemoveUser}
        handleClose={() => setIsPendingDelete(false)}
        dialogTitle="Remove User from Organization"
        message="Are you sure you want to remove this user from the organization?"
        confirmText="Remove"
      />
    </div>
  );
};

export { useStyles };

