import React from 'react';
import Button from '@material-ui/core/Button';
import { ModalStep } from '../add-organization/step';
import { CollectionCameraDetailSearchGetResponseDataCameras } from '../../api/isc-api';
import { Grid } from '@material-ui/core';
import { AttachCamerasTable } from './attach-cameras-table';

type AddCameraToOrganizationSuccessProps = {
  onDone: () => void;

  cameras: CollectionCameraDetailSearchGetResponseDataCameras[];
  submitButtonLabel?: string;
  organizationName: string;
};

export const AddCameraToOrganizationSuccess = (
  props: AddCameraToOrganizationSuccessProps
) => {
  return (
    <ModalStep
      title={'ATTACH/TRANSFER CAMERAS'}
      subtitle={
        <>
          Operation succeeded! The following cameras were attached to{' '}
          <strong>{props.organizationName}</strong>
        </>
      }
      onSubmit={() => props.onDone()}
      actions={
        <React.Fragment>
          <Button type="submit" color="primary" size="small">
            {'Done'}
          </Button>
        </React.Fragment>
      }
    >
      <Grid container xs={12}>
        <Grid item xs={12}>
          <AttachCamerasTable
            cameras={props.cameras}
            headers={['IMEI', 'Camera Name', 'Previous Organization']}
          />
        </Grid>
      </Grid>
    </ModalStep>
  );
};

