import React, { useEffect, useState } from 'react';

import { ModalStep } from '../add-organization/step';
import { FirmwareAPI } from '../../api/firmware';
import { useApiUpdate } from '../../hooks';
import {
  FirmwarePatch,
  FirmwarePost,
  FirmwareStatus,
  PatchFirmwareResponse,
  PostFirmwareResponseData,
} from '../../api/isc-api';
import { FirmwareForm } from './NewFirmwareVersionForm';

import { setTimeout } from 'timers';
import { Box, LinearProgress, Typography } from '@material-ui/core';
import { useStyles } from './useStyles';
type UploadNewFileProps = {
  onCancel: () => void;
  onNext: () => void;
  title: string;
  subtitle: string;
  firmwareForm: FirmwareForm;
  file: any;
};
type UploadFirmware = { uploadUrl: string; file: any };
type PatchFirmware = { firmwareVersionId: string; data: FirmwarePatch };
export const UploadNewFile = (props: UploadNewFileProps) => {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(true);
  const [error, setError] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [getFirmwareStatus, isLoadingFirmwareStatus] = useApiUpdate(
    (firmwareVersionId: string) => FirmwareAPI.getFirmware(firmwareVersionId)
  );
  const [doCreateFirmware, isWorking] = useApiUpdate<
    FirmwarePost,
    PostFirmwareResponseData
  >(
    body => FirmwareAPI.create(body),
    result => {
      return result;
    }
  );

  const [doPatchFirmwareStatus, isUpdatingFirmwareStatus] = useApiUpdate<
    PatchFirmware,
    PatchFirmwareResponse
  >(
    ({ firmwareVersionId, data }) =>
      FirmwareAPI.patchFirmwareStatus(firmwareVersionId, data),
    result => {
      return result;
    }
  );

  const [doUploadFile, isUploadingFile] = useApiUpdate<
    UploadFirmware,
    PostFirmwareResponseData
  >(
    ({ uploadUrl, file }) => FirmwareAPI.postFile(uploadUrl, file),
    result => {
      return result;
    }
  );

  useEffect(() => {
    saveFirmware(props.firmwareForm, props.file);
  }, []);

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        props.onNext();
      }, 2000);
    }
  }, [progress]);
  useEffect(() => {
    if (!!error) {
      setIsSaving(false);
    }
  }, [error]);

  const getFirmwareUploadStatus = async (firmwareVersionId: string) => {
    if (progress <= 90) {
      setProgress(progress => Math.min(progress + 1, 100));
    }

    const updatedFirmware = await getFirmwareStatus(firmwareVersionId);
    if (updatedFirmware) {
      if (updatedFirmware?.status === FirmwareStatus.Uploading) {
        await getFirmwareUploadStatus(firmwareVersionId);
      } else if (updatedFirmware?.status === FirmwareStatus.Failed) {
        setError('There was a problem with this upload');
      } else if (updatedFirmware?.status === FirmwareStatus.Ready) {
        setProgress(100);
      }
    }
  };
  const getBinaryFromFile = async file => {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.addEventListener('load', () => resolve(reader.result));
      reader.addEventListener('error', err => reject(err));

      reader.readAsBinaryString(file);
    });
  };
  const saveFirmware = async (firmwareForm, file) => {
    try {
      const postFirmwareResponse = await doCreateFirmware(firmwareForm);

      if (postFirmwareResponse) {
        let uploadUrl = postFirmwareResponse.url;
        let firmwareVersionId = postFirmwareResponse.firmwareVersionId;
        setProgress(Math.min(Math.round((100 * 1) / 4), 100));

        try {
          const binary = await getBinaryFromFile(file);

          try {
            await doUploadFile({ uploadUrl, file: binary }).then(
              async response => {
                setProgress(Math.min(Math.round((100 * 2) / 4), 100));
                await getFirmwareUploadStatus(firmwareVersionId);
              }
            );
          } catch (err) {
            // PATCH REQUEST
            await doPatchFirmwareStatus({
              firmwareVersionId,
              data: {
                status: FirmwareStatus.Failed,
              },
            });
            setError('This upload failed');
          }
        } catch (error) {
          setError(error.message);
        }
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const getSubtitle = () => {
    if (error) {
      return 'Oops! Something went wrong.';
    } else if (isUploadingFile) {
      return 'Uploading BIN file...';
    }

    return 'Processing file...';
  }

  return (
    <ModalStep
      title={props.title}
      subtitle={getSubtitle()}
    >
      <div>
        { isUploadingFile ? (
            <>
              <Box className={classes.progressWrapper}>
                <Box className={classes.progressBar}>
                  <LinearProgress variant="indeterminate" />
                </Box>
              </Box>
            </>
          ) : null
        }
        {!isUploadingFile && (isSaving || isWorking) ? (
          <Box className={classes.progressWrapper}>
            <Box className={classes.progressBar}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
            <Box className={classes.progressPercent}>
              <Typography variant="body2">{`${Math.round(
                progress
              )}%`}</Typography>
            </Box>
          </Box>
        ) : error ? (
          <>
            <Box className={classes.progressWrapper}>
              <Box className={classes.progressBar}>
                <LinearProgress variant="determinate" value={progress} />
              </Box>
              <Box className={classes.progressPercentError}>
                <Typography variant="body2">{`${Math.round(
                  progress
                )}%`}</Typography>
              </Box>
            </Box>
            <Box className={classes.progressError}>{error}</Box>
          </>
        ) : null}
      </div>
    </ModalStep>
  );
};

