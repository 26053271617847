import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { ConfirmDialogModal } from './confirm-dialog-modal';

interface Props {
  when?: boolean | undefined;
  navigate: (path: string) => void;
  message: string;
  shouldBlockNavigation: (location: Location) => boolean;
  confirmText?: string;
}

const RouteClickAwayGuard = ({
  when,
  message,
  confirmText,
  navigate,
  shouldBlockNavigation,
}: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />

      <ConfirmDialogModal
        open={modalVisible}
        value={''}
        handleConfirm={handleConfirmNavigationClick}
        handleClose={closeModal}
        dialogTitle=""
        message={message || 'Are you sure you want to leave?'}
        confirmText={confirmText || 'Continue without saving'}
      />
    </>
  );
};
export default RouteClickAwayGuard;

