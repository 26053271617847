import React, { useState } from 'react';

import { FirmwareForm, NewFirmwareVersionForm } from './NewFirmwareVersionForm';
import { SelectFirmwareFile } from './SelectFirmwareFile';
import { UploadNewFile } from './UploadNewFirmware';

export interface AddNewFirmwareVersionProps {
  onCancel: () => void;
  onSaved: () => void;
}

export const AddNewFirmwareVersion = (props: AddNewFirmwareVersionProps) => {
  const [step, setStep] = useState(1);
  const [selectedFile, setSelectedFile] = useState(null);
  const [firmwareForm, setFirmwareForm] = useState(null);
  const title = 'Add new firmware version';
  const subtitle =
    'Please select the firmware BIN file from your computer to upload. On completion, newly-activated cameras will receive the new version immediately. Any cameras currently operating will receive the new firmware version the next time they restart.';
  const handleAddNewFirmwareVersion = (file: any) => {
    setSelectedFile(file);
    setStep(2);
  };

  const handleSaveFirmwareVerion = (formValues: FirmwareForm) => {
    setFirmwareForm(formValues);
    setStep(3);
  };

  const handleSubmit = () => {
    props.onSaved();
  };

  switch (step) {
    case 1:
      return (
        <SelectFirmwareFile
          title={title}
          subtitle={subtitle}
          onCancel={props.onCancel}
          onNext={handleAddNewFirmwareVersion}
        />
      );
    case 2:
      return (
        <NewFirmwareVersionForm
          title={title}
          subtitle={subtitle}
          onCancel={props.onCancel}
          file={selectedFile}
          onNext={handleSaveFirmwareVerion}
        />
      );
    case 3:
      return (
        <UploadNewFile
          title={title}
          subtitle={subtitle}
          onCancel={props.onCancel}
          file={selectedFile}
          firmwareForm={firmwareForm}
          onNext={handleSubmit}
        />
      );
  }
};

