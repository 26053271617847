import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(theme => ({
  collectionListItem: {
    backgroundColor: theme.palette.common.white,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: '1px solid rgba(64,60,61,0.24)',
    marginBottom: theme.spacing(2),
    transition: 'background-color 0.33s ease',

    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  organizationLink: {
    display: 'block',
    width: '100%',
    color: theme.palette.common.black,
  },
  collectionLink: {
    display: 'block',
    width: '100%',
    textDecoration: 'none',
    '&:hover, &:focus, &:active': {
      textDecoration: 'none',
    },
  },
  cameraCard: {
    alignItems: 'center',
  },
  justifyRight: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  cameraName: {
    fontWeight: 'bold',
    fontSize: '0.9em',
    color: theme.palette.common.black,
  },
  cameraIMEI: {
    fontSize: '0.9em',
    color: theme.palette.common.black,
    marginBottom: theme.spacing(0.25),
  },
  cameraThumbnailDate: {
    fontSize: '0.85em',
    color: theme.palette.grey[600],
  },
}));
