import React from 'react';
import { Dialog } from '@material-ui/core';
import { AttachCameraToOrganization, AttachCameraToOrganizationProps } from '.';

interface AttachCameraToOrganizationModalProps
  extends AttachCameraToOrganizationProps {
  open: boolean;
}

export default function AttachCameraToOrganizationModal(
  props: AttachCameraToOrganizationModalProps
) {
  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        aria-labelledby="attach-camera-to-organization-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <AttachCameraToOrganization
          organization={props.organization}
          handleCancel={props.handleCancel}
          handleSave={props.handleSave}
        />
      </Dialog>
    </React.Fragment>
  );
}

