import React from 'react';
import { Dialog } from '@material-ui/core';
import { AddOrganization } from '.';

type CreateOrganizationModalProps = {
  open: boolean;
  handleSave: (id?: string) => void;
  handleCancel: () => void;
};

export const CreateOrganizationModal = (
  props: CreateOrganizationModalProps
) => {
  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <AddOrganization
          onCancel={props.handleCancel}
          onSaved={props.handleSave}
        />
      </Dialog>
    </React.Fragment>
  );
};

