import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

    display: 'flex',
    verticalAlign: 'center',
  },
  searchBoxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  header: {
    margin: theme.spacing(0),
  },
  DarkNewButton: {
    marginLeft: theme.spacing(2),

    borderColor: theme.palette.common.black,
  },
  lightNewButton: {
    marginLeft: theme.spacing(2),

    borderColor: theme.palette.common.white,
  },
  dark: {
    '& *, & *:hover, & *:active, & *:focus, &:before, &:after, & .MuiInput-underline:before, & .MuiInput-underline:after, & #searchField-label':
      {
        color: theme.palette.common.black,
        borderColor: theme.palette.common.black,
      },
  },
  light: {
    '& *, & *:hover, & *:active, & *:focus, &:before, &:after, & .MuiInput-underline:before, & .MuiInput-underline:after, & #searchField-label':
      {
        color: theme.palette.common.white,
        borderColor: theme.palette.common.white,
      },
  },
}));

