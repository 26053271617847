import React from 'react';
import { UpdateBody } from '../../pages/organization-details';

import {
  OrganizationDetailPage,
  OrganizationDetailPagePut,
  UserDetails,
} from '../../api/isc-api';

import { AddOrganizationAdmin } from '../add-organization/AddOrganizationAdmin';
import { LoadingAddUserToOrganization } from './loading-add-user-to-organization';

export interface OrganizationAddProps {
  organization: OrganizationDetailPage;
  handleCancel: () => void;
  handleSave: (users: UserDetails[]) => void;
  updateOrganization: (
    value: UpdateBody
  ) => Promise<void | OrganizationDetailPagePut>;
}

export const AddUserToOrganization = (props: OrganizationAddProps) => {
  const [step, setStep] = React.useState(1);
  const [users, setUsers] = React.useState<UserDetails[]>([]);

  const handleAddOrganizationAdminNext = (userDetails: UserDetails[]) => {
    setUsers([...props.organization.users, ...userDetails]);
    setStep(2);
  };

  switch (step) {
    case 1:
      return (
        <AddOrganizationAdmin
					title='Add Additional Organization Admin Users'
					subtitle='Enter the email address of the additional administrator user(s) you’d like to add to this organization. All new user(s) will be granted full management rights, including the ability to create and manage other users.'
          onCancel={props.handleCancel}
          onNext={handleAddOrganizationAdminNext}
          skipUsers={props.organization.users}
          submitButtonLabel={'Save'}
        />
      );
    case 2:
      return (
        <LoadingAddUserToOrganization
          updateOrganization={props.updateOrganization}
          onSaved={props.handleSave}
          organizationId={props.organization.organizationId}
          updateObject={{
            organizationName: props.organization.organizationName,
            features: props.organization.features,
            users,
          }}
        />
      );
  }
};

