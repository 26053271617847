import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  cameraCard: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'left',
  },
  status: {
    display: 'flex',
    justifyContent: 'left',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
    },
  },
  simCardTitle: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  title: {
    fontSize: '16px',
  },

  simCardTable: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  detailsGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  cameraDetailsButton: {
    paddingTop: theme.spacing(3),

    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      justifyContent: 'start',
    },
  },
  cameraDetailTable: {
    [theme.breakpoints.down('sm')]: {
      columnSpan: 3,
    },
  },
  cameraDetails: {
    width: 'max-content',
  },
}));

