import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { DashboardSearchBox } from '../dashboard-search-box';
import clsx from 'clsx';

type PanelHeaderProps = {
  title: string;
  value?: string;
  buttonText?: string;
  placeholderText?: string;
  isDisabled?: boolean;
  titleSize?: any;
  filterSize?: any;
  onAddNew: () => void;
  onFilterChange?: (filter: string, searchType: string) => void;
  darkColors?: boolean;
};

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
    display: 'flex',
    verticalAlign: 'center',
  },
  searchBoxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  header: {
    margin: theme.spacing(0),
  },
  DarkNewButton: {
    marginLeft: theme.spacing(2),

    borderColor: theme.palette.common.black,
  },
  lightNewButton: {
    marginLeft: theme.spacing(2),

    borderColor: theme.palette.common.white,
  },
  dark: {
    '& *, & *:hover, & *:active, & *:focus, &:before, &:after, & .MuiInput-underline:before, & .MuiInput-underline:after, & #searchField-label':
      {
        color: theme.palette.common.black,
        borderColor: theme.palette.common.black,
      },
  },
  light: {
    '& *, & *:hover, & *:active, & *:focus, &:before, &:after, & .MuiInput-underline:before, & .MuiInput-underline:after, & #searchField-label':
      {
        color: theme.palette.common.white,
        borderColor: theme.palette.common.white,
      },
  },
}));

export const PanelHeader = (props: PanelHeaderProps) => {
  const { title, buttonText, onAddNew, onFilterChange } = props;

  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid
        item
        xs={12}
        md={props.titleSize ? props.titleSize : 6}
        className={clsx(
          classes.titleContainer,
          props.darkColors ? classes.dark : classes.light
        )}
      >
        <Typography
          variant="h1"
          className={clsx(
            classes.header,
            props.darkColors ? classes.dark : classes.light
          )}
        >
          {title}
        </Typography>
        {onAddNew && (
          <Button
            size="small"
            variant="outlined"
            disableElevation
            disabled={props.isDisabled}
            className={clsx(
              props.darkColors ? classes.DarkNewButton : classes.lightNewButton,
              props.darkColors ? classes.dark : classes.light
            )}
            startIcon={<Add />}
            onClick={onAddNew}
          >
            {buttonText || 'New'}
          </Button>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        md={props.filterSize ? props.filterSize : 6}
        className={clsx(
          classes.searchBoxContainer,
          props.darkColors ? classes.dark : classes.light
        )}
      >
        {
          <DashboardSearchBox
            className={clsx(props.darkColors ? classes.dark : classes.light)}
            value={''}
            onFilterChange={onFilterChange}
          />
        }
      </Grid>
    </Grid>
  );
};
