import React, { useState } from 'react';
import Button from '@material-ui/core/Button';

import { ModalStep } from './step';
import { OrganizationDetailPageFeatures } from '../../api/isc-api';

import {
  OrganizationFeatures,
  ValidateWhitelistDomain,
} from '../organization-features';

type SetOrganizationFeaturesProps = {
  onCancel: () => void;

  handleSave: (features: OrganizationDetailPageFeatures) => void;
};

// type UpdateBody = Partial<UserDetailsPostPutBody>;

export const SetOrganizationFeatures = ({
  onCancel,

  handleSave,
}: SetOrganizationFeaturesProps) => {
  const [error, setError] = React.useState<string>(null);
  const [features, setFeatures] = useState({
    enableLpr: true,
    requireWarrantForLpr: true,
    enableMediaSharing: true,
    defaultMediaDomainWhiteList: '',
    enableFacialRecognition: false,
    requireWarrantForFacialRecognition: false,
  });

  const handleChange = values => {
    setFeatures(values);
  };

  const handleSubmit = () => {
    if (!!features.defaultMediaDomainWhiteList) {
      const isAllEmailValid = ValidateWhitelistDomain(
        features.defaultMediaDomainWhiteList
      );
      setError(
        isAllEmailValid
          ? null
          : 'Please enter valid list of domains separated by commas.'
      );
      if (isAllEmailValid) {
        features.defaultMediaDomainWhiteList =
          features.defaultMediaDomainWhiteList.replace(/\s/g, '');
        handleSave(features);
      }
    } else {
      handleSave(features);
    }
  };

  return (
    <ModalStep
      title={'ORGANIZATION FEATURES'}
      subtitle="Set organization features"
      onCancel={onCancel}
      onSubmit={handleSubmit}
      actions={
        <React.Fragment>
          <Button onClick={onCancel}>Cancel</Button>
          <Button type="submit" color="primary">
            {'Save'}
          </Button>
        </React.Fragment>
      }
    >
      <OrganizationFeatures
        features={features}
        onChange={handleChange}
        error={error}
      />
    </ModalStep>
  );
};
