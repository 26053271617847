import React, { useEffect, useContext, useState } from 'react';
import { useHistory, Link, Redirect, useParams, useLocation } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import spotlightLogoImg from '../../images/login.svg';
import portalBg from '../../images/portal-bg@2x.jpg';

import AppContext, {
  AuthenticationFlow,
  SessionStorageKeys,
} from '../../context';
import { AlertSnack } from '../../components/alert-snack';
import { Auth } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import { Grid, LinearProgress } from '@material-ui/core';
import { usePathAfterLogin } from './usePathAfterLogin';
import { useStyles } from './useStyles';
import { Logo } from '../page/menu-logged-in/logo';
import { AuthorizingUser } from '../../components/authorizing';

export const SignInPage = () => {
  const classes = useStyles({});
  const history = useHistory();
	const location = useLocation();
  const context = useContext(AppContext);
  const [step, setStep] = useState<AuthenticationFlow>(context.authStatus);
  const [redirectToReferrer, setRedirectToReferrer] = React.useState(false);
  const pathAfterLogin = usePathAfterLogin();
			const showAuthError = location.pathname === "/login/unauthorized";

  const isAttemptingAmplifyLogin = () =>
    context.authStatus === AuthenticationFlow.AttemptingFederatedSignIn ||
    context.authStatus === AuthenticationFlow.AttemptingOIDCLogin;

  const handleLogout = async () => {
    try {
      // This will trigger an event we listen to in `src/pages/app/useAmplify.ts`
      // where `context.onLogout()` then cleans up our side (context + sessionStorage)
      await Auth.signOut();

    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

	
  useEffect(() => {
    // If we have a session + organization, assume login
    if (context.sessionInfo && context.isAdminUser) {
			<Redirect to="/" />
    }
		else if (context.sessionInfo && context.authStatus === AuthenticationFlow.AttemptingAuthorization) {
      setStep(AuthenticationFlow.AttemptingAuthorization);
      // context.onAlert(
      //   'You are not authorized to access the administrative portal.',
      //   'error'
      // );
	

    }
    // If we're in the auth flow, show authenticating progress bar
    else if (isAttemptingAmplifyLogin()) {
      setStep(AuthenticationFlow.AttemptingFederatedSignIn);
      context.startAmplifyTimeoutCheck();
		
    } 

    // Default to login view
    else {
      setStep(AuthenticationFlow.LoggedOut);
    }
  }, [context.sessionInfo, context.authStatus]);

  const handleOIDCLogin = () => {
    window.sessionStorage.setItem(
      SessionStorageKeys.AuthState,
      AuthenticationFlow.AttemptingFederatedSignIn
    );

    Auth.federatedSignIn().catch(error => {
      context.onAlert(`Login error: ${error}`, 'error');
      context.onLogout();
    });
  };
	const handleAdminUser = () => {
    const sessionInfo = {
      ...context.sessionInfo,
			isAdminUser: true
    };
    context.onLogin(sessionInfo, "/");
  };

	const handleUnAuthorizedUser = () => {
		history.push("/login/unauthorized");

	}

	
  const showLogin =
    step === AuthenticationFlow.LoggedOut;
		const showAuthorizing = step === AuthenticationFlow.AttemptingAuthorization;
  return (
    <div className={classes.loginGlobalContainer}>
      <CssBaseline />
      <img src={portalBg} className={classes.loginBackground} alt="" />

      <Container
        component="main"
        maxWidth="xs"
        className={classes.loginContainer}
      >
        <div className={classes.paper}>
          <Logo
            className={classes.spotlightLogo}
            image={spotlightLogoImg}
            login
            light
          />

					{showAuthorizing && <AuthorizingUser handleAdminUser={handleAdminUser} handleUnAuthorizedUser={handleUnAuthorizedUser}/>}

          {showLogin && (
            <Grid container spacing={3}>
              <Grid item xs={12} className={classes.signInContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => handleOIDCLogin()}
                >
                  Sign In
                </Button>
              </Grid>
            </Grid>
          )}

          {isAttemptingAmplifyLogin() && (
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.signInContainer}>
                <Typography variant="subtitle1">
                  Authenticating&hellip;
                </Typography>
                <div className={classes.progress}>
                  <LinearProgress />
                </div>
              </Grid>
            </Grid>
          )}
        </div>

				{showAuthError &&  
        <AlertSnack
          message={"You are not authorized to access the administrative portal."}
          type={"error"} 
          onClose={() => handleLogout()}  
        /> }
      </Container>
    </div>
  );
};
