import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useApi, useApiUpdate } from '../../hooks';
import { PageHeader } from '../../components/page-header';
import {
  OrganizationDetailPage,
  OrganizationDetailPagePut,
} from '../../api/isc-api';
import { OrganizationDetailsAPI } from '../../api/organization-details';
import { ProgressBar } from '../../components/progress-bar';

import { useStyles } from './useStyles';

import { UsersPanel } from './users-panel';
import { CameraPanel } from './camera-panel';

import FeaturesPanel from './features-panel';
import { AlertSnack } from '../../components/alert-snack';
import { OrganizationsApi } from '../../api/organizations';
import { ConfirmDialogModal } from '../../components/confirm-dialog-modal';
import { useHistory } from 'react-router';
import RouteClickAwayGuard from '../../components/route-click-away-guard';
import { EditableTitle } from '../../components/editable-title';
export type UpdateBody = {
  id: string;
  body: OrganizationDetailPagePut;
};
export const OrganizationDetails = (props: any) => {
  const classes = useStyles();
  const organizationId = props.match.params.organizationId;
  const history = useHistory();
  const [organization, , isLoading] = useApi(() =>
    OrganizationDetailsAPI.getOrganization(organizationId)
  );
  const [org, setOrganization] = useState<OrganizationDetailPage>(organization);
  const [showAlert, setShowAlert] = useState(null);
  const [doGetOrganization, isGettingOrganization] = useApiUpdate(
    () => OrganizationDetailsAPI.getOrganization(organizationId),
    (result: OrganizationDetailPage) => {
      return result;
    }
  );
  const [isPendingDelete, setIsPendingDelete] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [doUpdateOrganization, isWorking] = useApiUpdate<
    UpdateBody,
    OrganizationDetailPagePut
  >(
    ({ id, body }) => OrganizationDetailsAPI.updateOrganization(id, body),
    result => {
      return result;
    }
  );
  const [doDeleteOrganization, isDeleting] = useApiUpdate(
    (id: string) => OrganizationsApi.delete(id),
    result => {
      return result;
    }
  );

  const handleTitleChange = async (title: string) => {
    try {
      await doUpdateOrganization({
        id: organizationId,
        body: {
          features: org.features,
          users: org.users.map(user => ({
            userId: user?.userId,
          })),
          organizationName: title,
        },
      });
      setOrganization({ ...org, organizationName: title });
    } catch (err) {
      setShowAlert({
        type: 'error',
        message: 'There was an issue changing the name of this organization.',
      });
    }
  };

  const handleDeleteOrganization = async () => {
    setIsPendingDelete(false);
    try {
      await doDeleteOrganization(organizationId).then(response => {
        history.push('/');
      });
    } catch (err) {
      setShowAlert({
        type: 'error',
        message: 'There was a problem deleting this Organization.',
      });
    }
  };

  useEffect(() => {
    setOrganization(organization);
  }, [organization]);

  if (isLoading) {
    return <ProgressBar />;
  }

  return (
    <>
      <PageHeader
        editableTitle={
          <EditableTitle
            title={org.organizationName}
            onTitleChange={handleTitleChange}
          />
        }
        breadcrumbs={[
          {
            label: 'Organizations',
            href: '/',
          },
        ]}
        button={
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={() => setIsPendingDelete(true)}
          >
            Delete Organization
          </Button>
        }
      />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6}>
          <UsersPanel
            setShowAlert={setShowAlert}
            organization={org}
            isLoading={isLoading}
            updateOrganization={doUpdateOrganization}
            setOrganization={setOrganization}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CameraPanel
            setShowAlert={setShowAlert}
            organization={org}
            isLoading={isLoading}
            setOrganization={setOrganization}
            getOrganization={doGetOrganization}
          />
        </Grid>

        <Grid item xs={12} xl={12}>
          <FeaturesPanel
            organization={org}
            isChanged={isChanged}
            setIsChanged={setIsChanged}
            setOrganization={setOrganization}
            updateOrganization={doUpdateOrganization}
            setShowAlert={setShowAlert}
          />
        </Grid>
        {Boolean(showAlert) && (
          <AlertSnack
            message={showAlert.message}
            type={showAlert.type}
            onClose={() => {
              console.log(showAlert);
            }}
          />
        )}
        <ConfirmDialogModal
          open={isPendingDelete}
          value={isPendingDelete}
          handleConfirm={handleDeleteOrganization}
          handleClose={() => setIsPendingDelete(false)}
          dialogTitle="Delete Organization"
          message="Are you sure you want to delete this organization? This action will delete organization and detach all users and cameras."
          confirmText="Confirm"
        />
        <RouteClickAwayGuard
          when={isChanged}
          navigate={path => history.push(path)}
          message={`You have made changes without saving, are you sure you want to continue?`}
          shouldBlockNavigation={location => {
            if (isChanged) {
              return true;
            }
            return false;
          }}
        />
      </Grid>
    </>
  );
};

export { useStyles };

