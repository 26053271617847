import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ListItem from '@material-ui/core/ListItem';

import { ModalStep } from './step';
import { SearchAPI } from '../../api/search';

type CreateNewOrganizationProps = {
  organization: string;
  onCancel: () => void;
  onNext: (organization: string) => void;
};

export const CreateNewOrganization = (props: CreateNewOrganizationProps) => {
  const [organization, setOrganization] = React.useState(props.organization);
  const [error, setError] = React.useState<string>(null);

  const handleNext = async () => {
    const isValid = !!organization;
    setError(isValid ? null : 'Please enter organization name');
    if (isValid) {
      try {
        const searchResults = await SearchAPI.organizations({
          limit: 1,
          filter: organization,
          start: 0,
        });

        !!searchResults.organizations[0]
          ? setError(
              'An organization with this name already exists. Please choose a unique organization name to continue.'
            )
          : props.onNext(organization);
      } catch (err) {
        setError('Something went wrong');
      }
    }
  };

  return (
    <ModalStep
      title={'CREATE NEW ORGANIZATION'}
      subtitle={'Please enter the name of the new organization.'}
      onCancel={props.onCancel}
      actions={
        <React.Fragment>
          <Button onClick={props.onCancel} size="small">
            Cancel
          </Button>
          <Button type="submit" color="primary" size="small">
            Next
          </Button>
        </React.Fragment>
      }
      onSubmit={handleNext}
    >
      <ListItem>
        <TextField
          label="Organization Name"
          fullWidth
          autoFocus
          value={organization}
          onChange={ev => setOrganization(ev.target.value)}
          error={Boolean(error)}
          helperText={error}
        />
      </ListItem>
    </ModalStep>
  );
};

