import { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import {
  AppContextType,
  AuthenticationFlow,
  LocalStorageKeys,
} from '../../context';
import { AuthAPI } from '../../api/auth';
import { ENABLE_DEV_CONSOLE } from '../../util/globals';
import { useAdminUserCheck } from '../../components/useAdminUser';

const approvedAnonymousRoutes = ['/login', '/sharing/:shareId'];

/**
 * Auth Listener is reactive, and looks to our context for clues
 * about the current session state.
 *
 * This functionality is responsible for redirecting when a session
 * is missing and attempting to refresh a session on tab reload/activation
 *
 * - Redirect to /login when we don't detect a session
 * - Attempt to refresh the session and handle unprotected routes
 */

export const useAuthListener = (context?: AppContextType) => {
  const history = useHistory();
  const isUnprotectedRoute =
    useRouteMatch(approvedAnonymousRoutes)?.isExact ?? false;
		const [ isAdminUser, isLookingUp ] = useAdminUserCheck();
  const setPathAndRedirect = () => {
    // If we don't have a post-login URL saved
    if (!window.localStorage.getItem(LocalStorageKeys.PathAfterLogin)) {
      window.localStorage.setItem(
        LocalStorageKeys.PathAfterLogin,
        history.location.pathname
      );
    }
  };

  const getValidation = async () => {
    const isAdmin = await context.validateUserIsAdmin();
    return isAdmin;
  };

  useEffect(() => {
    // If we're missing session info, try to grab an existing session.
    // Typical use case is on browser reload or opening a new tab.
    if (
      !context.sessionInfo &&
      context.authStatus === AuthenticationFlow.LoggedOut && !isLookingUp
    ) {
      ENABLE_DEV_CONSOLE &&
        console.log(`[AuthListener] Attempting session refresh`);

      context.setAuthStatus(AuthenticationFlow.AttemptingSessionRefresh);


      AuthAPI.session()
        .then(sessionInfo => {
          context.onLogin({
            ...sessionInfo,
						isAdminUser: isAdminUser.isAdminUser
          });
        })
        .catch(() => {
          if (isUnprotectedRoute) {
            return;
          }

          context.onLogout();
          setPathAndRedirect();
        });
      // If we're not in the middle of attempting a refresh, set auth checked to true
    } else {
      context.setAuthChecked(true);
    }
  }, [context.sessionInfo]);
};
