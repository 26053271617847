import { Grid } from '@material-ui/core';
import React from 'react';

import { PanelHeader } from '../../components/panel/panel-header';

import useDashboard, { DashboardStateProps } from './useDashboard';
import DashboardBody from './dashboard-layout/DashboardBody';
import { CreateOrganizationModal } from '../../components/add-organization/CreateOrganizationModal';
import { useHistory } from 'react-router';
type DashboardPageProps = {};
export enum OrganizationsDashboardSearchTypes {
  Organizations = 'Organizations',
  Cameras = 'Cameras',
}
export const DashboardPage = ({}: DashboardPageProps) => {
  const {
    firstLoad,
    cameras,
    organizations,
    type,
    search,
    filter,
    error,
    loading,
    isAddingOrganization,
    setIsAddingOrganization,
    handleFilterChange,
  }: DashboardStateProps = useDashboard();
  const history = useHistory();
  const onSave = id => {
    setIsAddingOrganization(false);
    history.push(`/organization/${id}`);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PanelHeader
          title={search ? 'Search Results' : type}
          value={filter}
          onAddNew={
            search || type === OrganizationsDashboardSearchTypes.Cameras
              ? null
              : () => {
                  setIsAddingOrganization(true);
                }
          }
          onFilterChange={handleFilterChange}
        />
      </Grid>

      <DashboardBody
        firstLoad={firstLoad}
        loading={loading}
        error={error}
        search={search}
        type={type}
        cameras={cameras}
        organizations={organizations}
      />

      <CreateOrganizationModal
        open={isAddingOrganization}
        handleSave={onSave}
        handleCancel={() => setIsAddingOrganization(false)}
      />
    </Grid>
  );
};
