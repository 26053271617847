import { Card, Fade, Grid } from '@material-ui/core';
import React from 'react';

import { ProgressBar } from '../../../components/progress-bar';
import {
  CollectionCameraDetailSearchGetResponseDataCameras,
  OrganizationDetailBasic,
} from '../../../api/isc-api';
import CameraListItem from '../../../components/camera-list-item';
import { OrganizationListItem } from '../../../components/organization-list-item';
import { DashboardResultsLayout } from '../dashboard-layout/DashboardResultsLayout';
import { AlertSnack } from '../../../components/alert-snack';
import { OrganizationsDashboardSearchTypes } from '..';
import { useStyles } from './useStyles';

type DashboardBodyProps = {
  firstLoad: boolean;
  loading: boolean;
  search: boolean;
  error: any;
  type: OrganizationsDashboardSearchTypes;
  cameras: CollectionCameraDetailSearchGetResponseDataCameras[];
  organizations: OrganizationDetailBasic[];
};
export default function DashboardBody({
  firstLoad,
  loading,
  error,
  search,
  type,
  cameras,
  organizations,
}: DashboardBodyProps) {
  const classes = useStyles();
  const getCameraListItem = () => {
    return cameras.map(
      (camera: CollectionCameraDetailSearchGetResponseDataCameras) => (
        <CameraListItem camera={camera} />
      )
    );
  };
  const getOrganizationListItem = () => {
    return organizations.map((organization: OrganizationDetailBasic) => (
      <OrganizationListItem organization={organization} />
    ));
  };

  const getNonSearch = () => {
    if (type === OrganizationsDashboardSearchTypes.Cameras)
      return [
        <Grid item xl={12} xs={12}>
          <Card className={classes.noResults}>
            Please enter Camera Name or IMEI.
          </Card>
        </Grid>,
      ];
    return getOrganizationListItem();
  };

  function getSearchResults(): JSX.Element[] {
    return type === OrganizationsDashboardSearchTypes.Cameras &&
      !!cameras &&
      cameras.length > 0
      ? getCameraListItem()
      : type === OrganizationsDashboardSearchTypes.Organizations &&
        !!organizations &&
        organizations.length > 0
      ? getOrganizationListItem()
      : [
          <Grid item xl={12} xs={12}>
            <Card className={classes.noResults}>No Results Found</Card>
          </Grid>,
        ];
  }
  if (firstLoad) {
    return (
      <Grid container spacing={2}>
        <Grid item xl={12} xs={12} className={classes.loadingBar}>
          <ProgressBar />
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid item xs={12}>
      <Fade in={!firstLoad}>
        <Grid container spacing={2}>
          {loading ? (
            <Grid item xl={12} xs={12} className={classes.loadingBar}>
              <ProgressBar />
            </Grid>
          ) : (
            <>
              {!!error ? null : search ? (
                <DashboardResultsLayout results={getSearchResults()} />
              ) : (
                <DashboardResultsLayout results={getNonSearch()} />
              )}
            </>
          )}
        </Grid>
      </Fade>
      {error && (
        <AlertSnack
          message={error.message}
          type={error.type}
          onClose={() => console.log(error)}
        />
      )}
    </Grid>
  );
}

