import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  firmwareFormVersionNumber: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  firmwareFormLabel: {
    marginRight: '8px',
    flexGrow: 1,
    whiteSpace: 'nowrap',
  },
  firmwareVersionInput: {
    '& .MuiInputBase-root': {
      maxHeight: '32px',
    },
  },
  helperText: {
    color: '#d32f2f',
  },
  progressWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px',
  },
  progressBar: {
    width: '100%',
    mr: 2,
  },
  progressPercent: {
    minWidth: 35,
    marginLeft: '8px',
    color: theme.palette.text.secondary,
  },
  progressPercentError: {
    color: theme.palette.error.main,
  },
  progressError: {
    color: theme.palette.error.main,
  },
}));

