import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import { CollectionCameraDetailSearchGetResponseDataCameras } from '../../api/isc-api';

const useStyles = makeStyles(theme => ({
  tableHeader: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: theme.palette.common.black,
  },
  tableBody: {
    fontSize: '14px',
    color: theme.palette.common.black,
  },
}));

type AttachCamerasTableProps = {
  headers: string[];
  cameras: CollectionCameraDetailSearchGetResponseDataCameras[];
};
export const AttachCamerasTable = ({
  cameras,
  headers,
}: AttachCamerasTableProps) => {
  const classes = useStyles();
  return (
    <Table size="small">
      <TableRow>
        {headers.map(header => (
          <TableCell id={header} className={classes.tableHeader}>
            {header}
          </TableCell>
        ))}
      </TableRow>
      <TableBody>
        {cameras.map(camera => (
          <TableRow key={camera.camera.cameraId}>
            <TableCell className={classes.tableBody}>
              {camera.camera.imei}
            </TableCell>
            <TableCell className={classes.tableBody}>
              {camera.camera.cameraName || 'Unnamed Camera'}
            </TableCell>
            <TableCell className={classes.tableBody}>
              {camera?.organizationDetails?.organizationName || '-'}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

