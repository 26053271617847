import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

import { SvgIconComponent } from '@material-ui/icons';

import { OperationStatusSummary } from '../api/isc-api';

import { BatteryIcon } from '../components/status-icons/icon-battery';
import { StorageIcon } from '../components/status-icons/icon-storage';
import { CellularIcon } from '../components/status-icons/icon-cellular';
import { WifiIcon } from '../components/status-icons/icon-wifi';
type CameraStatusProps = {
  operationStatus: OperationStatusSummary;
};

type CameraStatusElementProps = {
  value: any;
  Icon: SvgIconComponent;
};

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    paddingLeft: theme.spacing(0.25),
    paddingRight: theme.spacing(1),
  },
  icon: {
    padding: '4px',
  },
}));

export const CameraStatus = ({ operationStatus }: CameraStatusProps) => {
  const classes = useStyles();

  const CameraStatusElement = (props: CameraStatusElementProps) => {
    const Icon = props.Icon;
    return (
      <React.Fragment>
        <Icon fontSize="small" />
        <span className={classes.label}>{props.value}</span>
      </React.Fragment>
    );
  };

  return (
    <Box className={classes.root}>
      {operationStatus ? (
        <>
          <BatteryIcon
            operationStatus={operationStatus}
            className={classes.icon}
          />
          <StorageIcon
            operationStatus={operationStatus}
            className={classes.icon}
          />
          <CellularIcon
            operationStatus={operationStatus}
            className={classes.icon}
          />
          <WifiIcon
            operationStatus={operationStatus}
            className={classes.icon}
          />
        </>
      ) : (
        'Camera status unknown'
      )}
    </Box>
  );
};
