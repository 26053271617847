import React from 'react';

import Button from '@material-ui/core/Button';

import { ModalStep } from '../add-organization/step';

import { CollectionCameraDetailSearchGetResponseDataCameras } from '../../api/isc-api';
import { Grid } from '@material-ui/core';
import { SearchAPI } from '../../api/search';
import { NotFoundException } from '../../api/lib/domain';
import { DebounceAutocomplete } from '../debounce-autocomplete';

type CameraMultiSelect = {
  onCancel: () => void;
  onNext: (
    cameras: CollectionCameraDetailSearchGetResponseDataCameras[]
  ) => void;
  skipCameras?: CollectionCameraDetailSearchGetResponseDataCameras[];
  submitButtonLabel?: string;
};

export const AddCameraToOrganization = (props: CameraMultiSelect) => {
  const [error, setError] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<
    CollectionCameraDetailSearchGetResponseDataCameras[]
  >([]);
	const [loading, setLoading] = React.useState(false);


  const [inputValue, setInputValue] = React.useState('');
  const [selectedCameras, setSelectedCameras] = React.useState<
    CollectionCameraDetailSearchGetResponseDataCameras[]
  >([]);
	React.useEffect(() => {	
			setLoading(open && options.length === 0)
		
		
	}, [options.length, open, inputValue ])
  React.useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }

    (async () => {
      const cameras = await SearchAPI.cameras({ filter: inputValue, start: 0 })
        .then(async response => {
          return response.cameras;
        })
        .catch(err => {
          if (err instanceof NotFoundException) {
            return null;
          }
          throw err;
        });

      if (active) {
        if (cameras.length === 0) {
					setOpen(false);
					setLoading(false);
          setError('No cameras matching this IMEI were found in the system.');
          return;
        }
        setError(null);
        if (props.skipCameras && props.skipCameras.length > 0) {
          const skip = props.skipCameras.map(a => a.camera.cameraId);
          const camerasOptions = cameras.filter(
            (a: CollectionCameraDetailSearchGetResponseDataCameras) =>
              !skip.includes(a.camera.cameraId)
          );
          setOptions([...camerasOptions]);
        } else {
          setOptions([...cameras]);
        }
      }
    })();
    return () => {
      active = false;
    };
  }, [loading, inputValue]);

  const handleNext = () => {
    const isValid = selectedCameras.length > 0;
    setError(
      isValid ? null : 'Please add at least one IMEI number to continue.'
    );
    if (isValid) {
      props.onNext(selectedCameras);
    }
  };
  const handleSearchInput = input => {
    setOptions([]);
    setInputValue(input);
  };

  return (
    <ModalStep
      title={'ATTACH/TRANSFER CAMERAS'}
      subtitle={
        'Please enter the IMEI of the camera you would like to attach to this organization.'
      }
      onCancel={props.onCancel}
      actions={
        <React.Fragment>
          <Button onClick={props.onCancel} size="small">
            Cancel
          </Button>
          <Button type="submit" color="primary" size="small">
            {'Next'}
          </Button>
        </React.Fragment>
      }
      onSubmit={handleNext}
    >
      <Grid container xs={12}>
        <Grid item xs={12}>
          <DebounceAutocomplete
            handleSelectedOptions={newCameras => setSelectedCameras(newCameras)}
            value={inputValue}
            placeholder={'Camera IMEI'}
            error={error}
            options={options}
            loading={loading}
            open={open}
            setOpen={isOpen => setOpen(isOpen)}
            characterMin={3}
            onSearchInput={handleSearchInput}
          />
        </Grid>
      </Grid>
    </ModalStep>
  );
};

