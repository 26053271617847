import { useAppContext } from "../pages/app/useAppContext"
import {  Redirect, Route } from "react-router-dom";
import React, { useContext } from "react";
import { useAdminUserCheck } from "./useAdminUser";
import AppContext from "../context";


export function PrivateRoute({ component: Component, ...rest }) {
  const context = useContext(AppContext);
	console.log({isAdmin: context.isAdminUser })

  return (
    <Route
      {...rest}
      render={props =>
   			context.isAdminUser  ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )
      }
    />
  );
}