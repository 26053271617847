import React, { useState, useContext } from 'react';
import { DesktopMenu } from './desktop';
import { Drawer, Typography } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';

import { MobileMenu } from './mobile';
import AppContext from '../../../context';
import logoImage from '../../../images/logo-black.svg';
import { useStyles } from './useStyles';
import { drawerWidth } from '../constants';
import { Logo } from './logo';

export const MenuLoggedIn = () => {
  const context = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleLogout = async () => {
    try {
      // This will trigger an event we listen to in `src/pages/app/useAmplify.ts`
      // where `context.onLogout()` then cleans up our side (context + sessionStorage)
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };
  return (
    <>
      <DesktopMenu
        drawerWidth={drawerWidth}
        drawerOpen={open}
        onLogout={handleLogout}
        onToggleDrawer={() => setOpen(true)}
      />

      <Drawer
        variant="temporary"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
        onBackdropClick={() => setOpen(false)}
      >
        <div className={classes.toolbarIcon}>
          <Logo />
        </div>
        <MobileMenu onLogout={handleLogout} setOpen={setOpen} />
      </Drawer>
    </>
  );
};
