import * as React from 'react';
import { Switch, Route, withRouter } from 'react-router';

import { DashboardPage } from '../dashboard';
import { SignInPage } from '../login';
import AppContext from '../../context';
import { Page } from '../page';

import { Error404 } from '../404';
import { SubmitPage } from '../submit';
import { FirmwarePage } from '../firmware';
import { ThemeProvider } from '@material-ui/core/styles';
import Theme from '../../theme';

import { useAppContext } from './useAppContext';
import { useAuthListener } from './useAuthListener';
import { useAmplifyListener } from './useAmplify';
import { OrganizationDetails } from '../organization-details';
import { PrivateRoute } from '../../components/private-route';

const AppComponent = () => {
  const context = useAppContext();
  useAmplifyListener(context);
  useAuthListener(context);

  return (
    <AppContext.Provider value={context}>
      <ThemeProvider theme={Theme}>
        <Switch>
          <Route exact path="/login" component={SignInPage} />
          <Route exact path="/login/unauthorized" component={SignInPage} />
          <Route exact path="/submit" component={SubmitPage} />

          <Route>
            <Page>
              <Switch>
                <PrivateRoute exact path={'/'} component={DashboardPage} />
                <Route exact path="/firmware" component={FirmwarePage} />
                <PrivateRoute
                  exact
                  path="/organization/:organizationId"
                  component={OrganizationDetails}
                />

                <Route component={Error404} />
              </Switch>
            </Page>
          </Route>
        </Switch>
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export const App = withRouter(AppComponent);
