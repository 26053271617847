import React from 'react';
import { Card } from '@material-ui/core';

import clsx from 'clsx';

import fromNow from 'from-now';
import { MediaImage } from '../../api/isc-api';
import { useStyles } from './useStyles';
import { Image } from '../image';

type CameraCardProps = {
  cameraThumbnail: MediaImage;
  onClick?: () => void;
};

export const CameraCard = ({ cameraThumbnail, onClick }: CameraCardProps) => {
  const classes = useStyles();

  const hasClick = typeof onClick !== 'undefined';

  // Eliminates a bug where a camera timestamp slightly ahead of local time causes fromNow() to bomb out
  const getElapsedTime = date => {
    let elapsedTime;
    try {
      elapsedTime = fromNow(cameraThumbnail.date.dateTimeLocal);
    } catch (e) {
      elapsedTime = 'A few seconds';
    }

    return elapsedTime;
  };

  const timestamp = cameraThumbnail?.date?.dateTimeLocal
    ? `${getElapsedTime(cameraThumbnail.date.dateTimeLocal)} ago`
    : 'No recent images';

  return (
    <Card
      className={clsx(hasClick && classes.hasClick, classes.cardWrapper)}
      onClick={onClick}
    >
      <div className={classes.cardMediaWrapper}>
        <Image src={cameraThumbnail?.url} className={classes.photoContainer} />
        <div className={classes.timestamp}>{timestamp}</div>
      </div>
    </Card>
  );
};
