import {
  Chip,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Done } from '@material-ui/icons';
import React from 'react';
import { SimCard, SimCards } from '../api/isc-api';
export type NumericStrings = ['0', '1', '2', '3'];
export enum SimCardsLabel {
  External,
  FirstNet,
  ATT,
  Verizon,
}

// note the key is NumericStrings[AxisLabel], not AxisLabel
export const labelLookup: Record<NumericStrings[SimCardsLabel], string> = {
  [SimCardsLabel.External]: 'External',
  [SimCardsLabel.FirstNet]: 'FirstNet',
  [SimCardsLabel.ATT]: 'AT&T',
  [SimCardsLabel.Verizon]: 'Verizon',
};
type SimCardTableProps = {
  simCards: SimCards;
};

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    paddingLeft: theme.spacing(0.25),
    paddingRight: theme.spacing(1),
  },
  icon: {
    padding: '4px',
  },
  cell: {
    padding: '8px 4px',
  },
  tableBody: {
    fontSize: '12px',
  },
  tableHeading: {
    fontWeight: 'bold',
    fontSize: '12px',
  },
  row: { '&:last-child td, &:last-child th': { border: 0 } },
  simCardTable: {
    [theme.breakpoints.down('sm')]: {
      overflow: 'auto',
    },
  },
}));

export const SimCardTable = ({ simCards }: SimCardTableProps) => {
  const classes = useStyles();
  const Cell = (label: string, isHeader?: Boolean) => {
    return (
      <TableCell className={classes.cell}>
        <Typography
          className={isHeader ? classes.tableHeading : classes.tableBody}
        >
          {label}
        </Typography>
      </TableCell>
    );
  };
  return (
    <div className={classes.simCardTable}>
      <Table
        aria-label="sim-cards"
        padding="checkbox"
      >
        <TableHead>
          <TableRow>
            {Cell('Sim', true)}
            {Cell('IICID', true)}
            {Cell('IMSI', true)}
            <TableCell className={classes.cell}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {simCards &&
            simCards.map((sim: SimCard) => (
              <TableRow key={sim.imsi} className={classes.row}>
                {Cell(`${labelLookup[sim.simNumber.toString()]}`)}
                {Cell(`${sim.iccid}`)}
                {Cell(`${sim.imsi}`)}

                <TableCell className={classes.cell}>
                  {sim.isActive && (
                    <Chip
                      label={'Active'}
                      avatar={<Done />}
                      size={'small'}
                      className={classes.tableBody}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

