import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { ModalStep } from './step';
import { Autocomplete } from '@material-ui/lab';
import { UserDetails } from '../../api/isc-api';
import { CircularProgress, Grid } from '@material-ui/core';
import { SearchAPI } from '../../api/search';
import { NotFoundException } from '../../api/lib/domain';

type AdminUserMultiSelect = {
  onCancel: () => void;
  onNext: (userDetails: UserDetails[]) => void;
  skipUsers?: UserDetails[];
  submitButtonLabel?: string;
	title?: string;
	subtitle?: string;
};

export const AddOrganizationAdmin = (props: AdminUserMultiSelect) => {
  const [error, setError] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<UserDetails[]>([]);
  const loading = open && options.length === 0;
  const [inputValue, setInputValue] = React.useState('');
  const [selectedUsers, setSelectedUsers] = React.useState<UserDetails[]>([]);
  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const users = await SearchAPI.users({ filter: inputValue })
        .then(async users => {
          return users;
        })
        .catch(err => {
          if (err instanceof NotFoundException) {
            return null;
          }
          throw err;
        });

      if (active) {
        if (props.skipUsers) {
          const skip = props.skipUsers.map(a => a.name);
          const usersOptions = users.filter(
            (a: UserDetails) => !skip.includes(a.name)
          );
          setOptions([...usersOptions]);
        } else {
          setOptions([...users]);
        }
      }
    })();
    return () => {
      active = false;
    };
  }, [loading, inputValue]);

  const handleNext = () => {
    const isValid = selectedUsers.length > 0;
    setError(isValid ? null : 'Please enter valid email address');
    if (isValid) {
      props.onNext(selectedUsers);
    }
  };

  return (
    <ModalStep
      title={props.title || 'ADD ORGANIZATION ADMIN'}
      subtitle={props.subtitle ||
        'Enter the email address of the administrator of this organization. This user will be granted full management rights, including the ability to create and manage other users.'
      }
      onCancel={props.onCancel}
      actions={
        <React.Fragment>
          <Button onClick={props.onCancel} size="small">
            Cancel
          </Button>
          <Button type="submit" color="primary" size="small">
            {props.submitButtonLabel || 'Next'}
          </Button>
        </React.Fragment>
      }
      onSubmit={handleNext}
    >
      <Grid container xs={12}>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            multiple
            id="add-user"
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            filterSelectedOptions
            options={options}
            loading={loading}
            onChange={(event, value) => {
              if (Array.isArray(value)) {
                const newusers = value as UserDetails[];
                setSelectedUsers(newusers);
              }
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            getOptionLabel={option => `${option.name} (${option.email})`}
            renderInput={params => (
              <TextField
                {...params}
                error={Boolean(error)}
                helperText={error}
                placeholder={'Name or email address'}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </ModalStep>
  );
};
