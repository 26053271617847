import {
  Button,
  Collapse,
  Grid,
  TableCell,
  Typography,
} from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import React from 'react';
import { CameraCard } from '../../../../components/camera-card';
import { CameraStatus } from '../../../../components/camera-status';
import { SimCardTable } from '../../../../components/sim-card-table';
import { useStyles } from './useStyles';

type CameraDetailsCollapseProps = {
  camera: any;
  open: boolean;
  organizationId: string;
};

export const CameraDetailsCollapse = ({
  camera,
  open,
  organizationId,
}: CameraDetailsCollapseProps) => {
  const classes = useStyles();
  const CAMERA_API_BASE = process.env.PUBLIC_PORTAL_URL;
  return (
    <TableCell
      style={{ padding: 0, margin: 0 }}
      className={classes.cameraDetailTable}
      colSpan={4}
    >
      <Collapse in={open} timeout="auto" unmountOnExit>
        <>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={6} md={6} className={classes.cameraCard}>
              <CameraCard cameraThumbnail={camera.cameraThumbnail} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.detailsGrid}>
              <div className={classes.cameraDetails}>
                <div className={classes.status}>
                  <CameraStatus operationStatus={camera.operationStatus} />
                </div>
                <div className={classes.cameraDetailsButton}>
                  <a
                    target={'_blank'}
                    href={`${CAMERA_API_BASE}/camera/${camera.camera.cameraId}/${organizationId}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <Button
                      size="small"
                      variant="outlined"
                      disableElevation
                      startIcon={<OpenInNew />}
                    >
                      Details
                    </Button>
                  </a>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12} xl={12} className={classes.simCardTitle}>
              <Typography
                variant="h6"
                component="div"
                className={classes.title}
              >
                Sim Cards
              </Typography>
            </Grid>
            <Grid item xs={12} xl={12} className={classes.simCardTable}>
              <SimCardTable simCards={camera.simCards} />
            </Grid>
          </Grid>
        </>
      </Collapse>
    </TableCell>
  );
};

