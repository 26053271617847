import React, { useEffect } from 'react';

import Button from '@material-ui/core/Button';
import { ModalStep } from './step';

import { Typography } from '@material-ui/core';
import { ProgressBar } from '../progress-bar';

import { OrganizationsApi } from '../../api/organizations';
import { useApiUpdate } from '../../hooks';
import {
  OrganizationAddPostBody,
  OrganizationDetailBasic,
} from '../../api/isc-api';

type LoadingAddNewOrganizationProps = {
  onSaved: (id?: string) => void;
  updateObject: OrganizationAddPostBody;
};

export const LoadingAddNewOrganization = (
  props: LoadingAddNewOrganizationProps
) => {
  const [error, setError] = React.useState<string>(null);
  const [isSaving, setIsSaving] = React.useState(false);
  const [doCreateOrganization, isWorking] = useApiUpdate<
    OrganizationAddPostBody,
    OrganizationDetailBasic
  >(
    body => OrganizationsApi.create(body),
    result => {
      setIsSaving(false);
      props.onSaved(result?.organizationId);
    }
  );
  useEffect(() => {
    const newOrg = {
      ...props.updateObject,
      users: props.updateObject.users.map(user => ({
        userId: user.userId,
        isOrganizationAdmin: true,
      })),
    };
    saveOrganization(newOrg);
  }, []);

  const saveOrganization = async (
    organizationObject: OrganizationAddPostBody
  ) => {
    setIsSaving(true);
    try {
      const organization = await doCreateOrganization(organizationObject);
      console.log(organization);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <ModalStep
      title={'Creating New Organization'}
      subtitle={'Please wait while new organization is created'}
      actions={
        <React.Fragment>
          <Button type="submit" color="primary" disabled>
            {'Saving'}
          </Button>
        </React.Fragment>
      }
    >
      {isSaving && !error ? (
        <ProgressBar />
      ) : (
        <Typography variant={'h6'} color="error">
          {error}
        </Typography>
      )}
    </ModalStep>
  );
};

