import React, { useEffect, useState } from 'react';
import {
  CollectionCameraDetailSearchGetResponseDataCameras,
  OrganizationDetailBasic,
} from '../../api/isc-api';
import { OrganizationsDashboardSearchTypes } from '.';

import { OrganizationsApi } from '../../api/organizations';
import { SearchAPI } from '../../api/search';

function useDashboard() {
  const [firstLoad, setIsFirstLoad] = useState(true);
  const [filter, setFilter] = useState(null);
  const [search, setSearch] = useState(false);

  const [isAddingOrganization, setIsAddingOrganization] = useState(false);
  const [organizations, setOrganizations] = useState<OrganizationDetailBasic[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(
    OrganizationsDashboardSearchTypes.Organizations
  );
  const [error, setError] = useState(null);
  const [cameras, setCameras] =
    useState<CollectionCameraDetailSearchGetResponseDataCameras[]>(null);

  useEffect(() => {
    fetchInitalOrganizations();
  }, []);

  async function fetchInitalOrganizations() {
    try {
      const data = await OrganizationsApi.get();

      if (data) {
        setOrganizations(data?.organizations);
      }
      setIsFirstLoad(false);
    } catch (err) {
      setError({ message: err.message, type: 'error' });
    }
  }
  async function getCameras({ filter }) {
    return await SearchAPI.cameras({
      onlyIncludeCamerasWithoutCollection: false,
      limit: 100,
      filter,
    });
  }
  async function getOrganizations({ filter }) {
    return await SearchAPI.organizations({
      limit: 100,
      filter,
      start: 0,
    });
  }

  async function handleFilterChange(
    searchQuery: string,
    type: OrganizationsDashboardSearchTypes
  ) {
    setLoading(true);
    setType(type);
    setError('');
    setFilter(searchQuery);
    if (searchQuery === '') {
      if (type === OrganizationsDashboardSearchTypes.Organizations) {
        fetchInitalOrganizations();
      }
      setSearch(false);
    } else {
      try {
        if (type === OrganizationsDashboardSearchTypes.Cameras) {
          const searchResults = await getCameras({ filter: searchQuery });
          setCameras(searchResults?.cameras);
        } else {
          const searchResults = await getOrganizations({ filter: searchQuery });
          setOrganizations(
            searchResults?.organizations as OrganizationDetailBasic[]
          );
        }
      } catch (err) {
        setError({ message: err.message, type: 'error' });
      }

      setSearch(true);
    }
    setLoading(false);
    //Update cameras component state model with search results
  }
  return {
    firstLoad,
    filter,
    search,
    isAddingOrganization,
    setIsAddingOrganization,
    organizations,
    loading,
    type,
    error,
    cameras,
    handleFilterChange,
  };
}

export type DashboardStateProps = {
  firstLoad: boolean;
  filter: string;
  search: boolean;
  isAddingOrganization: boolean;
  loading: boolean;
  type: OrganizationsDashboardSearchTypes;
  error: any;
  cameras: CollectionCameraDetailSearchGetResponseDataCameras[];
  organizations: OrganizationDetailBasic[];

  handleFilterChange: (
    searchQuery: string,
    type: OrganizationsDashboardSearchTypes
  ) => void;
  setIsAddingOrganization: React.Dispatch<React.SetStateAction<boolean>>;
};

export default useDashboard;

