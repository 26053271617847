/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * common api response fields
 */
export interface BaseResponse {
  /** optional debugging information for developers (typically disabled in production) */
  debug?: DebugData;
}

/**
 * optional debugging information for developers (typically disabled in production)
 */
export interface DebugData {
  /**
   * echoes request information in the response back to the caller, this
   * is intended to help developers validate that they are requesting resources
   * correctly
   *
   */
  request?: DebugDataRequest;
  identity?: DebugDataIdentity;
}

/**
* RFC 7807 Compliant problem details (https://tools.ietf.org/html/rfc7807)

Problem details are not a debugging tool for the underlying
implementation; rather, they are a way to expose greater detail about
the HTTP interface itself.  Designers of new problem types need to
carefully consider the Security Considerations (Section 5), in
particular, the risk of exposing attack vectors by exposing
implementation internals through error messages.
*/
export type Problem = BaseResponse;

export interface BaseMedia {
  /**
   * @format uuid
   * @example 415c83bb-a7a0-4bc1-bd16-5af2d86860e8
   */
  mediaId?: string;

  /**
   * the unique id for the media set in which this media resides
   * @format uuid
   * @example 615c83bb-a7a0-4bc1-bd16-5af2d86860e1
   */
  mediaSetId?: string;
}

/**
 * media image data
 */
export type MediaImage = BaseMedia & {
  mediaType?: string;
  contentType?: string;
  url?: string;
  usage?: string;
  dimensions?: Dimensions;
  date?: DateTimeDetail;
};

/**
 * dimension information
 */
export interface Dimensions {
  /**
   * @min 1
   * @example 600
   */
  width?: number;

  /**
   * @min 1
   * @example 600
   */
  height?: number;
}

/**
 * basic camera information (cameraId and name)
 */
export interface CameraBasic {
  /**
   * @format uuid
   * @example 3e8bb452-38b8-43fb-b2e1-794673f28a39
   */
  cameraId?: string;

  /** @example Hirsh Residence */
  cameraName?: string;

  /**
   * International Mobile Equipment Identity
   * @example 35-209900-176148-23
   */
  imei?: string;

  /**
   * @format uuid
   * @example b7604c0c-6e42-45f1-bf1f-5fe42d86c589
   */
  cameraCollectionId?: string;

  /** @example Downtown Perrysburg */
  cameraCollectionName?: string;
}

/**
 * static metadata for the physical camera
 */
export interface CameraMetadata {
  /**
   * model number for the device
   * @example SC410
   */
  modelNumber?: string;

  /**
   * serial number for the device
   * @example SN2334948
   */
  serialNumber?: string;
}

/**
 * camera collection information
 */
export interface CameraCollection {
  /**
   * @format uuid
   * @example b7604c0c-6e42-45f1-bf1f-5fe42d86c589
   */
  cameraCollectionId?: string;

  /** @format uuid */
  parentCollectionId?: string | null;

  /** @example Downtown Perrysburg */
  cameraCollectionName?: string;

  /**
   * this is the count of total cameras (remember we're limiting the number returned) that belong to the specified parent collection
   * @min 0
   * @example 23
   */
  cameraCount?: number;

  /**
   * @min 0
   * @example 5
   */
  childCollectionCount?: number;

  /** if cameraCount is zero and childCollectionCount is zero, this collection is considered empty and could be eligible for deletion */
  isEmpty?: boolean;

  /**
   * nested collections currently support a maximum depth of 3 levels.
   *
   * Example:
   *   - Level 0 - Root / Null Collection
   *     - Level 1 - City Center (supportsChildCollections  = true)
   *       - Level 2 - Downtown Crime Watch (supportsChildCollections  = true)
   *         - Level 3 - Levis Square (supportsChildCollections  = false)
   */
  supportsChildCollections?: boolean;
}

/**
 * sim card
 */
export interface SimCard {
  simNumber?: number;
  iccid?: string;
  imsi?: string;
  isActive?: boolean;
}

/**
 * sim cards attached to camera
 */
export type SimCards = SimCard[];

/**
 * User Reset Password POST Response
 */
export type UserResetPasswordPutResponse = BaseResponse & { data?: string };

/**
 * operation status summary
 */
export interface OperationStatusSummary {
  /**
   * @format uuid
   * @example 6e8ba452-38b8-43fb-b2e1-794673f28a39
   */
  operationStatusId?: string;

  /**
   * battery operation status details
   *
   * battery status labels:
   *   - percentRemaining <= 10  =>    "Critical"
   *   - percentRemaining <= 25  =>    "Low"
   *   - percentRemaining <= 90  =>    "Good"
   *   - percentRemaining > 90   =>    "Full"
   */
  battery?: BatteryDetail;

  /**
   * Storage space details.
   *
   * The percentage of remaining storage space labels:
   * - percentFree <= 10   =>    "Critical"
   * - percentFree <= 25   =>    "Low"
   * - percentFree <= 90   =>    "Good"
   * - percentFree > 90    =>    "Full"
   */
  storage?: StorageDetail;

  /**
   * Wi-Fi signal strength details. The signal strength is measured on a range of 0 to 3.
   *
   * Wi-Fi status labels:
   *   - strength = 0    => "Off"
   *   - strength <= 2   => "Weak"
   *   - strength <= 3   => "Good"
   *   - strength > 3    => "Strong"
   */
  wifi?: WifiDetail;

  /**
   * Cellular signal details. The signal strength is measured on a range of 0 to 4 bars.
   *
   * Cellular status labels:
   * - strength = 0    => "Off"
   * - strength <= 2   => "Weak"
   * - strength <= 3   => "Good"
   * - strength > 3    =>  "Strong"
   */
  cellular?: CellularDetail;

  /** date and time information */
  date?: DateTimeDetail;
}

/**
* battery operation status details

battery status labels:
  - percentRemaining <= 10  =>    "Critical"
  - percentRemaining <= 25  =>    "Low"
  - percentRemaining <= 90  =>    "Good"
  - percentRemaining > 90   =>    "Full"
*/
export interface BatteryDetail {
  /** @example Battery */
  operationStatusType?: 'Battery';

  /**
   * the percentage of the battery life remaining
   * @min 0
   * @max 100
   * @example 5
   */
  percentRemaining?: number | null;

  /**
   * the DC voltage of the camera battery
   * @example 11.05
   */
  voltage?: number | null;

  /**
   * a percentage <= 25% is considered "Low"
   * @example true
   */
  isLow?: boolean;

  /**
   * a percentage <= 10% is considered "Critical"
   * @example true
   */
  isCritical?: boolean;

  /**
   * this is a description of the battery charge status
   * - percentRemaining <= 10  =>    "Critical"
   * - percentRemaining <= 25  =>    "Low"
   * - percentRemaining <= 90  =>    "Good"
   * - percentRemaining > 90   =>    "Full"
   *
   * @example Low
   */
  status?: string;
}

/**
* Wi-Fi signal strength details. The signal strength is measured on a range of 0 to 3.

Wi-Fi status labels:
  - strength = 0    => "Off"
  - strength <= 2   => "Weak"
  - strength <= 3   => "Good"
  - strength > 3    => "Strong"
*/
export interface WifiDetail {
  /** @example Wifi */
  operationStatusType?: 'Wifi';

  /**
   * the wifi signal strength, the higher the value, the stronger the signal
   * @min 0
   * @max 3
   * @example 1
   */
  strength?: number;

  /**
   * the wifi signal strength expressed as a % from 0 to 100
   * @min 0
   * @max 100
   * @example 85
   */
  percentStrength?: number | null;

  /**
   * a value <= 1 is considered weak
   * @example true
   */
  isWeakSignal?: boolean | null;

  /**
   * this is a description of the wi-fi signal strength status
   * - strength = 0    => "Off"
   * - strength <= 2   => "Weak"
   * - strength <= 3   => "Good"
   * - strength > 3    =>  "Strong"
   *
   * @example Weak
   */
  status?: string;
}

/**
* Cellular signal details. The signal strength is measured on a range of 0 to 4 bars.

Cellular status labels:
- strength = 0    => "Off"
- strength <= 2   => "Weak"
- strength <= 3   => "Good"
- strength > 3    =>  "Strong"
*/
export interface CellularDetail {
  /** @example Cellular */
  operationStatusType?: 'Cellular';

  /**
   * the cellular signal strength, the higher the value, the stronger the signal
   * @min 0
   * @max 4
   * @example 2
   */
  strength?: number | null;

  /**
   * the cellular signal strength expressed as a % from 0 to 100
   * @min 0
   * @max 100
   * @example 85
   */
  percentStrength?: number | null;

  /**
   * a value <= 2 is considered weak
   * @example true
   */
  isWeakSignal?: boolean;

  /**
   * this is a description of the cellular signal strength status
   * - strength = 0    => "Off"
   * - strength <= 2   => "Weak"
   * - strength <= 3   => "Good"
   * - strength > 3    =>  "Strong"
   *
   * @example Weak
   */
  status?: string;
}

/**
* Storage space details.

The percentage of remaining storage space labels:
- percentFree <= 10   =>    "Critical"
- percentFree <= 25   =>    "Low"
- percentFree <= 90   =>    "Good"
- percentFree > 90    =>    "Full"
*/
export interface StorageDetail {
  /** @example Storage */
  operationStatusType?: 'Storage';

  /**
   * the percentage of the remaing free space
   * @min 0
   * @max 100
   * @example 8
   */
  percentFree?: number | null;

  /**
   * a percentage <= 25% is considered "Low"
   * @example true
   */
  isLow?: boolean;

  /**
   * a percentage <= 10% is considered "Critical"
   * @example true
   */
  isCritical?: boolean;

  /**
   * this is a description of the storage status
   * - percentFree <= 10   =>    "Critical"
   * - percentFree <= 25   =>    "Low"
   * - percentFree <= 90   =>    "Good"
   * - percentFree > 90    =>    "Full"
   *
   * @example Critical
   */
  status?: string;
}

/**
 * date and time information
 */
export interface DateTimeDetail {
  /**
   * the date and time represented in UTC
   * @format date-time
   * @example "2020-06-15T13:45:30.000Z"
   */
  dateTimeUtc?: string;

  /**
   * the date and time with an offset that represents the user's local timezone
   * @format date-time
   * @example "2020-06-15T20:45:30.000Z"
   */
  dateTimeLocal?: string;
}

export interface UserRole {
  /**
   * @format uuid
   * @example 0af853a8-fd89-425f-a2c9-426cc52e2e65
   */
  userRoleId?: string;

  /** @example Organization Admin */
  name?: string;
}

/**
 * add user to organization
 */
export type AddOrganizationUsersPostBody = AddOrganizationUsersPostBodyInner[];

export interface UserDetails {
  /** @format uuid */
  userId?: string;

  /**
   * @max 128
   * @example Agent Smith
   */
  name?: string;

  /**
   * @format email
   * @example smith@matrix.com
   */
  email?: string;
}

/**
 * data model for Bad Request (400) response.
 */
export type BadRequestModel = Problem & {
  formValidationMessages?: BadRequestModelFormValidationMessages[];
  type?: string;
  title?: string;
  status?: number;
  detail?: string;
  instance?: string;
  traceId?: string;
};
/**
 * data model for Gone model (404) response.
 */
export type GoneModel = Problem & {
  type?: string;
  title?: string;
  status?: number;
  detail?: string;
  instance?: string;
  traceId?: string;
};

/**
 * data model for Not Found (404) response.
 */
export type NotFoundModel = Problem & {
  type?: string;
  title?: string;
  status?: number;
  detail?: string;
  instance?: string;
  traceId?: string;
};

/**
 * data model for Unauthorized (401) response. The current request must be authenticated before accessing the requested resource.
 */
export type UnauthorizedModel = Problem & {
  type?: string;
  title?: string;
  status?: number;
  detail?: string;
  instance?: string;
  traceId?: string;
};

/**
 * data model for Forbidden (403) response. The current request lacks sufficent permission to perform the operation. A potential cause of this error is a mismatched x-organization header for the current user. This request will be logged (along with request details) by the server and flagged as potentially malicious.
 */
export type ForbiddenModel = Problem & {
  type?: string;
  title?: string;
  status?: number;
  detail?: string;
  instance?: string;
  traceId?: string;
};

/**
 * data model for Gateway Timeout (504) response. The current request ran for longer than the maximum allowable time. This request will be logged (along with request details) by the server.
 */
export type TimeoutModel = Problem & {
  type?: string;
  title?: string;
  status?: number;
  detail?: string;
  instance?: string;
  traceId?: string;
};

/**
 * Admin user get response
 */
export interface AdminUserGetResponse {
  /** @example true */
  isAdminUser?: boolean;
}

/**
 * Organization GET Response
 */
export type OrganizationsListGetResponse = BaseResponse & {
  data?: OrganizationsListGetResponseData;
};

/**
 * Organization GET Response organization detail
 */
export interface OrganizationDeleteResponse {
  data?: string;
}

/**
 * Organization GET Response organization detail
 */
export interface OrganizationDetailBasic {
  /** name of organization */
  organizationName?: string;

  /** id organization */
  organizationId?: string;
}

/**
 * Organization details
 */
export interface OrganizationDetailPagePut {
  /** name of organization */
  organizationName?: string;

  /** list of users associated with this org */
  users?: AddOrganizationUsersPostBodyInner[];

  /** features */
  features?: OrganizationDetailPageFeatures;
}

/**
 * Request to attach or transfer cameras to an oragnization
 */
export interface OrganizationAttachCamerasPut {
  /** list of cameras IDs to associate with org */
  cameraIds?: string[];
}

/**
 * Organization details
 */
export interface OrganizationDetailPage {
  /** id organization */
  organizationId?: string;

  /** name of organization */
  organizationName?: string;

  /** list of users associated with this org */
  users?: OrganizationDetailPageUsers[];

  /** list of cameras ids associated with org */
  cameras?: CameraDetailBody[];

  /** features */
  features?: OrganizationDetailPageFeatures;
}

/**
 * Organization details PUT response
 */
export type OrganizationsDetailsPutResponse = BaseResponse &
  OrganizationDetailPagePut &
  object;

/**
 * Organization details PUT response
 */
export type OrganizationAttachCamerasPutResponse = BaseResponse &
  OrganizationAttachCamerasPut &
  object;

/**
 * Organization details GET response
 */
export type OrganizationsDetailsGetResponse = BaseResponse &
  OrganizationDetailPage &
  object;

/**
 * Organization Search Response
 */
export type OrganizationGetSearchResponse = BaseResponse & {
  data?: OrganizationGetSearchResponseData;
};

/**
 * Camera Detail GET Response
 */
export interface CameraDetailBody {
  /** basic camera information (cameraId and name) */
  camera?: CameraBasic;

  /** operation status summary */
  operationStatus?: OperationStatusSummary;

  /** media image data */
  cameraThumbnail?: MediaImage;

  /** sim cards attached to camera */
  simCards?: SimCards;
}

/**
 * Camera Details DELETE Response
 */
export type RemoveCameraFromOrganization = BaseResponse & { data?: string };

/**
 * Collection Camera Detail Search GET Response
 */
export type CollectionCameraDetailSearchGetResponse = BaseResponse & {
  data?: CollectionCameraDetailSearchGetResponseData;
};

/**
 * User Details POST Response
 */
export type UserDetailsPostResponse = BaseResponse & {
  data?: UserDetailsPostResponseData;
};

/**
 * User Search GET Response
 */
export type UserSearchGetResponse = BaseResponse & { data?: UserDetails[] };

export interface OrganizationAddPostBody {
  users?: OrganizationAddPostBodyUsers[];

  /** @example Acme Organization */
  name: string;
  features?: OrganizationFeatures;
}

export interface OrganizationFeatures {
  enableLpr?: boolean;
  requireWarrantForLpr?: boolean;
  enableMediaSharing?: boolean;
  defaultMediaDomainWhiteList?: string;
  enableFacialRecognition?: boolean;
  requireWarrantForFacialRecognition?: boolean;
}

export interface IscFirmwareItem {
  /**
   * @format uuid
   * @example cf90b459-1ac1-49ba-b243-b7c3a1055267
   */
  firmwareVersionId?: string;

  /** @example 1.0.23 */
  versionNumber?: string;

  /**
   * the date and time represented in UTC
   * @format date-time
   * @example "2022-06-15T13:45:30.000Z"
   */
  uploadDate?: string;
  uploadUser?: UserDetails;

  /** @format uuid */
  checksum?: string;

  /** @example 72.1 MB */
  filesize?: string;
  status?: 'uploading' | 'ready' | 'failed';

  /** @example https:// */
  fileUrl?: string;

  /** @example Phasellus nunc neque, posuere eget vehicula eget, suscipit eu est. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In consectetur interdum nisi at maximus. Integer non elit enim. Sed lobortis orci velit, at rutrum felis sagittis id. Duis hendrerit eu nunc vitae ornare */
  releaseNotes?: string;
}

export interface LTEFirmwareItem {
  /**
   * @format uuid
   * @example cf90b459-1ac1-49ba-b243-b7c3a1055267
   */
  firmwareVersionId?: string;

  /** @example 1.0.23 */
  versionNumber?: string;

  /** @example Phasellus nunc neque, posuere eget vehicula eget, suscipit eu est. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In consectetur interdum nisi at maximus. Integer non elit enim. Sed lobortis orci velit, at rutrum felis sagittis id. Duis hendrerit eu nunc vitae ornare */
  releaseNotes?: string;
}

export type LteFirmwareArray = LTEFirmwareItem[];

export interface PostFirmwareResponse {
  data?: PostFirmwareResponseData;
}

export interface GetFirmwareResponse {
  iscFirmware?: IscFirmwareItem[];
  lteFirmware?: LteFirmwareArray;
}

export interface GetFirmwareVersionResponse {
  firmware?: IscFirmwareItem;
}

export interface PatchFirmwareResponse {
  data?: string;
}

export interface DeleteFirmwareResponse {
  data?: string;
}

export interface FirmwarePatch {
  status?: 'failed' | 'uploading' | 'ready';
}

export interface FirmwarePost {
  /** @example Phasellus nunc neque, posuere eget vehicula eget, suscipit eu est. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In consectetur interdum nisi at maximus. Integer non elit enim. Sed lobortis orci velit, at rutrum felis sagittis id. Duis hendrerit eu nunc vitae ornare */
  releaseNotes?: string;
  firmwareVersion?: string;
}

export interface FirmwarePut {
  file?: object;
}

export interface FirmwareDelete {
  data?: string;
}

/**
* echoes request information in the response back to the caller, this
is intended to help developers validate that they are requesting resources
correctly
*/
export interface DebugDataRequest {
  /**
   * request uri
   * @format uri
   * @example /alerts/camera/log/
   */
  uri?: string;

  /**
   * key / value pairs that describe each of the request path variables and values
   * @example {"alertScope":"Camera"}
   */
  pathValues?: Record<string, string>;

  /**
   * key / value pairs that describe each of the request query string variables and values. Note that a query parameter may be repeated, in which case the returned value would be denoted as a comma separated string
   * @example {"filter":"apple,dog,tree"}
   */
  queryValues?: Record<string, string>;

  /**
   * request body for requests with a JSON body
   * @example
   */
  jsonBody?: object;

  /**
   * key / value pairs that describe each of the request header variables and values. Note that a header may be repeated, in which case the returned value would be denoted as a comma separated string
   * @example {"x-organization":"659b5cd7-cba4-43a4-83d2-ec56d6d85df5","cache-control":"no-cache,no-store"}
   */
  headerValues?: Record<string, string>;
}

export interface DebugDataIdentity {
  /**
   * @format uuid
   * @example b8f82f27-aab9-4cf8-9fe0-bcec93822fa7
   */
  userId?: string;

  /** @example John Kimball */
  name?: string;

  /**
   * @format uuid
   * @example 331c55fa-eed6-4102-8374-9b5440a87a97
   */
  organizationId?: string;

  /** @example Hanson Inc. */
  organizationName?: string;
}

export interface AddOrganizationUsersPostBodyInner {
  /** @example 3fa85f64-5717-4562-b3fc-2c963f66afa6 */
  userId?: string;
}

export interface BadRequestModelFormValidationMessages {
  /** array of validation messages for the specified key */
  values?: string;
}

export interface OrganizationsListGetResponseData {
  /** list of organizations */
  organizations?: OrganizationDetailBasic[];
}

/**
 * features
 */
export interface OrganizationDetailPageFeatures {
  /** @example true */
  enableLpr?: boolean;

  /** @example true */
  requireWarrantForLpr?: boolean;

  /** @example true */
  enableMediaSharing?: boolean;

  /** @example intelligentsurveillancecorp.com */
  defaultMediaDomainWhiteList?: string;

  /** @example false */
  enableFacialRecognition?: boolean;

  /** @example false */
  requireWarrantForFacialRecognition?: boolean;
}

export interface OrganizationDetailPageUsers {
  /** @example 3fa85f64-5717-4562-b3fc-2c963f66afa6 */
  userId?: string;

  /** @example Gomez Addams */
  name?: string;

  /** @example gaddams@gmail.com */
  email?: string;
}

/**
 * features
 */
export interface OrganizationDetailPageFeatures {
  /** @example true */
  enableLpr?: boolean;

  /** @example true */
  requireWarrantForLpr?: boolean;

  /** @example true */
  enableMediaSharing?: boolean;

  /** @example intelligentsurvaliencecorp.com */
  defaultMediaDomainWhiteList?: string;

  /** @example false */
  enableFacialRecognition?: boolean;

  /** @example false */
  requireWarrantForFacialRecognition?: boolean;
}

export interface OrganizationGetSearchResponseDataOrganizations {
  /** Organization GET Response organization detail */
  organization?: OrganizationDetailBasic;
}

export interface OrganizationGetSearchResponseData {
  organizations?: OrganizationGetSearchResponseDataOrganizations[];

  /**
   * URI for the next page of results, if null, there are no additional results or collections
   * @format uri
   */
  next?: string;
}

export interface CollectionCameraDetailSearchGetResponseDataCameras {
  /** basic camera information (cameraId and name) */
  camera?: CameraBasic;

  /** media image data */
  cameraThumbnail?: MediaImage;

  /** Organization GET Response organization detail */
  organizationDetails?: OrganizationDetailBasic;

  /** camera collection information */
  cameraCollection?: CameraCollection;

  /** static metadata for the physical camera */
  cameraMetadata?: CameraMetadata;

  /** operation status summary */
  operationStatus?: OperationStatusSummary;
}

export interface CollectionCameraDetailSearchGetResponseData {
  cameras?: CollectionCameraDetailSearchGetResponseDataCameras[];

  /**
   * URI for the next page of results, if null, there are no additional results or collections
   * @format uri
   */
  next?: string;
}

export interface UserDetailsPostResponseDataAvailableOrganizationalRoles {
  /** is the user a member of this organizational role */
  isMember?: boolean;
}

export interface UserDetailsPostResponseData {
  userDetails?: UserDetails;

  /**
   * list of all available organizational roles
   * @example [{"userRoleId":"0af853a8-fd89-425f-a2c9-426cc52e2e65","name":"Organization Admin","isMember":true},{"userRoleId":"082cc145-a9b8-4395-b78f-b7f745c1d321","name":"Camera Collection Admin","isMember":false},{"userRoleId":"4ec30927-cfc3-48c7-81eb-f7c5ed6c9bd0","name":"Media Sharing","isMember":false}]
   */
  availableOrganizationalRoles?: UserDetailsPostResponseDataAvailableOrganizationalRoles[];
}

export interface OrganizationAddPostBodyUsers {
  /** @format uuid */
  userId?: string;

  /** @example true */
  isOrganizationAdmin?: boolean;
}

export interface PostFirmwareResponseData {
  /**
   * @format uuid
   * @example cf90b459-1ac1-49ba-b243-b7c3a1055267
   */
  firmwareVersionId?: string;

  /** @example https://isc-prod-firmware.s3.amazonaws.com/firmware_isc_5e7fd0c5-2774-4010-866a-234afb7bc295?AWSAccessKeyId=AKIAV7N22XSS4APTYMYD&Content-Type=application%2Foctet-stream&Expires=1675159615&Signature=2NEBTJcn6cl2iyiclKXbRs7Eycc%3D */
  url?: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}
export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export enum FirmwareStatus {
  Failed = 'failed',
  Uploading = 'uploading',
  Ready = 'ready',
}
