import {
  Card,
  CardActions,
  CardHeader,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { label } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { OrganizationDetailPageFeatures } from '../../api/isc-api';

import { useStyles } from './useStyles';
const FeaturesFormGroup = ({ title, items }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Grid container xs={12} md={12} spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.title}>
            {' '}
            {title}{' '}
          </Typography>
        </Grid>
      </Grid>
      <Grid container xs={12} md={12} spacing={1}>
        {items.map(item => (
          <React.Fragment key={item.label}>
            <Grid item xs={8} className={classes.label}>
              {item.label}
            </Grid>
            <Grid item xs={4} className={classes.action}>
              {item.action}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Grid>
  );
};
function validateWebsite(field) {
  const reg =
    /^[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\.([a-zA-Z]{1,6}|[a-zA-Z0-9-]{1,30}\.[a-zA-Z]{2,3})$/;
  return reg.test(field);
}
export const ValidateWhitelistDomain = (domain: string) => {
  const domains = domain
    .trim()
    .replace(/\s+/g, ' ')
    .split(/\s?,\s?/);
  return domains.every(validateWebsite);
};
type OrganizationFeaturesProps = {
  features: OrganizationDetailPageFeatures;
  error?: any;
  onChange: (features: OrganizationDetailPageFeatures) => void;
};
export const OrganizationFeatures = ({
  features,
  error,
  onChange,
}: OrganizationFeaturesProps) => {
  const classes = useStyles();
  let [formValues, setFormValues] = useState(features);

  useEffect(() => {
    setFormValues(features);
  }, [features]);
  const handleClick = (event, checked) => {
    const name = event.target.name;
    onChange({ ...formValues, [name]: checked });
  };
  const handleInput = event => {
    const name = event.target.name;
    const value = event.target.value;
    onChange({ ...formValues, [name]: value });
  };
  return (
    <Grid container xs={12} md={12}>
      <FeaturesFormGroup
        title={'License Plate Recognition'}
        items={[
          {
            label: 'License Plate Recognition',
            action: (
              <Switch
                name={'enableLpr'}
                checked={formValues.enableLpr}
                onChange={handleClick}
              />
            ),
          },
          {
            label: 'Require a Warrant for License Plate Recognition',
            action: (
              <Switch
                name={'requireWarrantForLpr'}
                checked={formValues.requireWarrantForLpr}
                onChange={handleClick}
              />
            ),
          },
        ]}
      />
      <FeaturesFormGroup
        title={'Media Sharing'}
        items={[
          {
            label: 'Media Sharing Enabled',
            action: (
              <Switch
                name={'enableMediaSharing'}
                checked={formValues.enableMediaSharing}
                onChange={handleClick}
              />
            ),
          },
          {
            label: 'Default Media Domain Whitelist',
            action: (
              <TextField
                fullWidth
                name={'defaultMediaDomainWhiteList'}
                value={formValues.defaultMediaDomainWhiteList}
                onChange={handleInput}
                error={Boolean(error)}
                helperText={error}
              />
            ),
          },
        ]}
      />
      <FeaturesFormGroup
        title={'Facial Recognition (not implemented yet)'}
        items={[
          {
            label: 'Facial Recognition Enabled',
            action: (
              <Switch
                checked={formValues.enableFacialRecognition}
                name={'enableFacialRecognition'}
                onChange={handleClick}
              />
            ),
          },
          {
            label: 'Require a Warrant for Facial Recognition',
            action: (
              <Switch
                checked={formValues.requireWarrantForFacialRecognition}
                name={'requireWarrantForFacialRecognition'}
                onChange={handleClick}
              />
            ),
          },
        ]}
      />
    </Grid>
  );
};

