import { API } from './lib/api';
import {
  CollectionCameraDetailSearchGetResponse,
  CollectionCameraDetailSearchGetResponseData,
  OrganizationGetSearchResponse,
  OrganizationGetSearchResponseData,
  OrganizationsListGetResponseData,
  UserSearchGetResponse,
} from './isc-api';

export interface CameraSearchParams {
  filter?: string;
  excludeUserGroupId?: string;
  onlyIncludeCamerasWithoutCollection?: string;
  start?: number;
  limit?: number;
}

export const SearchAPI = {
  /**
   * @description Search for admin users - filter users with `canManageOrganization` permission. filters user's name on partial string - used in add user details **Wireframe references** - [[AP2.1.1] Add User](https://yqyoqc.axshare.com/#g=1&p=_ap2_1_1__add_a_user)
   *
   * @tags Search, Organization Admin Users
   * @name UsersDetailsList
   * @summary Search For Admin Users
   * @request GET:/search/users/details
   * @secure
   */
  users: async (query?: {
    filter?: string;
  }): Promise<UserSearchGetResponse> => {
    return API.get(`/search/users/details`, {
      queryStringParameters: query,
    });
  },
  /**
   * @description Searches for cameras the user is authorized to access. Intended for use for dashboard search or any camera search that should include thumbnails and operation statuses. If these are not needed, you should use `/search/cameras/basic/` Additionally, by specifying `onlyIncludeCamerasWithoutCollection=true`, a list of cameras that are not assigned to a camera collection can be returned **Wireframe references** - [[P2] Dashboard](https://yqyoqc.axshare.com/#g=1&p=_p2__dashboard)
   *
   * @tags Search, Shifted APIs
   * @name CamerasDetailsList
   * @summary Search For Cameras
   * @request GET:/search/cameras/details
   * @secure
   */
  cameras: async (query?: {
    filter?: string;
    onlyIncludeCamerasWithoutCollection?: boolean | null;
    start?: number;
    limit?: number;
  }): Promise<CollectionCameraDetailSearchGetResponseData> => {
    return await API.get(`/search/cameras/details`, {
      queryStringParameters: query,
    });
  },
  /**
   * @description Searches for organization list on organization name returns matching results. Intended for use for dashboard search or organization creation **Wireframe references** - [[P2] Dashboard](https://yqyoqc.axshare.com/#g=1&p=_p2__dashboard) - [[AP2] Organizations](https://yqyoqc.axshare.com/#g=1&p=_ap2__organizations) - [[AP2a] Create Organizations](https://yqyoqc.axshare.com/#g=1&p=_ap2a__create_organization)
   *
   * @tags Search
   * @name OrganizationDetailsList
   * @summary Search Organizations
   * @request GET:/search/organization/details
   * @secure
   */
  organizations: async (query?: {
    filter?: string;
    start?: number;
    limit?: number;
  }): Promise<OrganizationGetSearchResponseData> => {
    return await API.get(`/search/organization/details`, {
      queryStringParameters: query,
    });
  },
};

