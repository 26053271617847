import { makeStyles } from '@material-ui/core/styles';
import { drawerWidth } from '../constants';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    backgroundColor: 'transparent',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '40px 25px 20px',
    marginBottom: '20px',
    ...theme.mixins.toolbar,
    '&::before': {
      content: '""',
      opacity: '0.9',
      zIndex: '-1',
      position: 'absolute',
      left: '0',
      right: '0',
      top: '-20px',
      height: '125px',
      background: theme.palette.grey[200],
      transform: 'skewY(-8deg)',
      transformOrigin: 'top',
      transition: 'opacity: 0.3s',
    },
  },
  iconColor: {
    htmlColor: '#fff',
    color: '#fff',
  },
  loginLogo: {
    maxHeight: '100px',
    margin: '0px',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '80px',
    },
  },

  logo: {
    maxHeight: '40px',
    margin: '0px',
    width: 'auto',
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    background: theme.palette.common.white,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },

  adminPortal: {
    color: theme.palette.common.black,
    margin: 0,
    padding: 0,
    fontSize: '11px',
  },
  loginAdminPortal: {
    color: theme.palette.common.white,
    padding: 0,
    fontSize: '16px',
  },
  loginAdminPortalText: {
    position: 'absolute',
    right: 0,
    bottom: 4,
  },
  text: {
    fontSize: '16px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  adminPortalText: {
    position: 'absolute',
    left: '152px',
    flexWrap: 'nowrap',

    bottom: 5,
  },
  light: {
    color: theme.palette.common.white,
  },
  logoContainer: { position: 'relative' },
}));
