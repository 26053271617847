import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  title: {
    paddingTop: '32px',
    paddingBottom: '16px',
  },
  label: {
    display: 'flex',

    alignItems: 'center',
  },
  action: {
    display: 'flex',
    padding: '16px',
    justifyContent: 'end',
  },
}));

