import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  firmwarePageHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '42px',
  },
  firmwareHeader: {
    textTransform: 'uppercase',
    fontSize: '18px',
    margin: '0px',
  },
  firmwareButton: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: '8px',
  },
  firmwarePaperContainer: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  firmwareVersionNumber: {
    fontSize: '30px',
    margin: '0px',
    fontWeight: 400,
  },
  currentFirmwareButtonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  firmwarePreviousVersionsContainer: {
    padding: '0px',
  },
  firmwareAccordian: {
    '&.Mui-expanded': {
      margin: '2px 0px',
    },
    '& .MuiAccordianSummary-content.Mui-expanded': {
      margin: '0px',
    },
  },
  firmwareAccordianHeader: {
    margin: 0,
  },
  checksum: {
    width: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  buttonIcon: {
    fontSize: 18,
    marginRight: '8px',
  },
  firmwareAccordianDetail: {
    width: '100%',
  },
}));

