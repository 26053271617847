import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  noResults: {
    padding: theme.spacing(4),
    margin: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
  },
  loadingBar: {
    padding: theme.spacing(5),
    margin: theme.spacing(5),
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
}));

