import React, {
  PropsWithChildren,
  useState,
  useEffect,
  useContext,
} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';

import { useLocation, useRouteMatch } from 'react-router-dom';
import clsx from 'clsx';
import AppContext, { AuthenticationFlow } from '../../context';
import { AlertSnack } from '../../components/alert-snack';

import { useStyles } from './useStyles';

import { MenuLoggedIn } from './menu-logged-in';
import Container from "@material-ui/core/Container";

export const Page = (props: PropsWithChildren<Record<string, unknown>>) => {
  const classes = useStyles(props);
  const location = useLocation();
  const testIsHome = useRouteMatch({
    path: ['/', '/collection/:cameraCollectionId'],
    exact: true,
  })?.isExact;
  const [isHome, setIsHome] = useState(testIsHome);
  const [fadeHomeShape, setFadeHomeShape] = useState(!isHome);

  const context = useContext(AppContext);

  try {
  } catch {
    context.onLogout();
  }

  useEffect(() => {
    if (isHome !== testIsHome) {
      setIsHome(testIsHome);
      setFadeHomeShape(!testIsHome);
    }
  }, [location.pathname]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <MenuLoggedIn />

      <main
        id="main-content"
        className={clsx({
          [classes.content]: true,
          [classes.contentHome]: isHome,
          [classes.contentHomeShapeFade]: fadeHomeShape,
        })}
      >
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {props.children}
        </Container>
      </main>
      <div id="alert-snack-portal" />
      <AlertSnack
        message={context.alert?.message}
        type={context.alert?.type}
        onClose={context.onAlert}
      />
    </div>
  );
};
