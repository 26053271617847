import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import React from 'react';
import usePagination from '../../../hooks/usePagination';
import { useStyles } from './useStyles';
type DashboardResultsLayoutProps = {
  results: JSX.Element[];
};
export const DashboardResultsLayout = ({
  results,
}: DashboardResultsLayoutProps) => {
  const classes = useStyles();
  const PER_PAGE = 20;
  const count = Math.ceil(results.length / PER_PAGE);
  const data = usePagination(results, PER_PAGE);

  const handleChange = (e, p) => {
    data.jump(p);
  };

  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        {data.currentData().map(v => v)}
      </Grid>
      <Grid
        container
        alignItems="center"
        className={classes.paginationContainer}
      >
        <Grid item>
          {results.length > PER_PAGE && (
            <Pagination
              count={count}
              page={data.currentPage}
              onChange={handleChange}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

