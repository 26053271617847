import React, { useState } from 'react';
import { Box, Button, makeStyles, Paper, Typography } from '@material-ui/core';
import { isEqual } from 'lodash';
import {
  OrganizationFeatures,
  ValidateWhitelistDomain,
} from '../../../components/organization-features';
import {
  OrganizationDetailPage,
  OrganizationDetailPageFeatures,
  OrganizationDetailPagePut,
} from '../../../api/isc-api';
import { UpdateBody } from '..';

type FeaturesPanelProps = {
  organization: OrganizationDetailPage;
  setOrganization: React.Dispatch<React.SetStateAction<OrganizationDetailPage>>;
  updateOrganization: (
    value: UpdateBody
  ) => Promise<void | OrganizationDetailPagePut>;
  setShowAlert: React.Dispatch<any>;
  isChanged: boolean;
  setIsChanged: React.Dispatch<React.SetStateAction<boolean>>;
};
const useStyles = makeStyles(theme => ({
  organizationFeaturesRoot: {
    padding: theme.spacing(3),

    marginBottom: theme.spacing(3),
  },
  actionBox: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
  },
}));

export default function FeaturesPanel({
  organization,
  isChanged,
  setIsChanged,
  setOrganization,
  updateOrganization,
  setShowAlert,
}: FeaturesPanelProps) {
  const [features, setFeatures] = useState<OrganizationDetailPageFeatures>(
    organization.features
  );

  const [error, setError] = React.useState<string>(null);

  const handleSave = async (features: OrganizationDetailPageFeatures) => {
    const updateBody = {
      users: organization.users,
      organizationName: organization.organizationName,
      features,
    };

    const result = await updateOrganization({
      body: updateBody,
      id: organization.organizationId,
    });
    if (result) {
      setOrganization({ ...organization, features: result?.features });
      setIsChanged(false);
    } else {
      setShowAlert({ type: 'Error', message: 'Something went wrong' });
    }
  };

  const handleSubmit = (features: OrganizationDetailPageFeatures) => {
    if (!!features.defaultMediaDomainWhiteList) {
      const isAllEmailValid = ValidateWhitelistDomain(
        features.defaultMediaDomainWhiteList
      );
      setError(
        isAllEmailValid
          ? null
          : 'Please enter valid list of domains separated by commas.'
      );
      if (isAllEmailValid) {
        features.defaultMediaDomainWhiteList =
          features.defaultMediaDomainWhiteList.replace(/\s/g, '');
        handleSave(features);
      }
    } else {
      handleSave(features);
    }
  };
  const compareFeatures = (clickedFeatures: OrganizationDetailPageFeatures) => {
    return isEqual(clickedFeatures, organization.features);
  };
  const handleFeaturesChange = (
    changedFeatures: OrganizationDetailPageFeatures
  ) => {
    setIsChanged(!compareFeatures(changedFeatures));

    setFeatures(changedFeatures);
  };
  const handleCancel = () => {
    setFeatures(organization.features);
    setIsChanged(false);
  };
  const classes = useStyles();
  return (
    <React.Fragment>
      <Paper elevation={2} className={classes.organizationFeaturesRoot}>
        <Typography variant="h2"> Organization Features</Typography>
        <OrganizationFeatures
          features={features}
          onChange={handleFeaturesChange}
          error={error}
        />
        <Box className={classes.actionBox}>
          <Button
            variant={'contained'}
            color={'primary'}
            disabled={!isChanged}
            onClick={() => handleSubmit(features)}
          >
            Save
          </Button>
          <Button disabled={!isChanged} onClick={handleCancel}>
            Cancel
          </Button>
        </Box>
      </Paper>
    </React.Fragment>
  );
}

