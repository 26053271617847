import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  organizationCard: {
    padding: theme.spacing(3),
  },
  organizationCardHeader: {
    display: 'flex',
    justifyContent: 'between',
    alignItems: 'center',
  },
  organizationCardTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  organizationCardSearchHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
  },
  organizationCardAction: {
    marginLeft: theme.spacing(1),
  },
  usersList: {
    color: theme.palette.primary.dark,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    cursor: 'pointer',
  },
  usersListItem: {
    color: theme.palette.primary.dark,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    '& .MuiItemText-root': {
      color: theme.palette.primary.dark,
    },
  },
}));

