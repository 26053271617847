import React, { useEffect } from "react";
import { ModalStep } from "../add-organization/step";
import { Typography } from "@material-ui/core";
import { ProgressBar } from "../progress-bar";
import {
  OrganizationAttachCamerasPut,
  OrganizationAttachCamerasPutResponse,
} from "../../api/isc-api";
import { useApiUpdate } from "../../hooks";
import { OrganizationDetailsAPI } from "../../api/organization-details";

type LoadingAddNewOrganizationProps = {
  onSaved: (users: OrganizationAttachCamerasPutResponse) => void;
  updateObject: OrganizationAttachCamerasPut;
  organizationId: string;
};
type AddCameraBody = {
  id: string;
  body: OrganizationAttachCamerasPut;
};
export const LoadingAttachCameraToOrganization = (
  props: LoadingAddNewOrganizationProps
) => {
  const [error, setError] = React.useState<string>(null);
  const [isSaving, setIsSaving] = React.useState(false);

  const [doAttachCamera, isWorking] = useApiUpdate<
    AddCameraBody,
    OrganizationAttachCamerasPutResponse
  >(
    ({ id, body }) => OrganizationDetailsAPI.attachCamera(id, body),
    (result) => {
      return result;
    }
  );
  useEffect(() => {
    saveOrganization(props.updateObject);
  }, []);

  const saveOrganization = async (
    organizationCameraObject: OrganizationAttachCamerasPut
  ) => {
    setIsSaving(true);
    try {
      const results = await doAttachCamera({
        id: props.organizationId,
        body: organizationCameraObject,
      });
      if (results) {
        props.onSaved(results);
      } else {
        setError(
          "Operation failed. Please try again with fewer cameras. If you continue to experience this error, please contact team@thespotlightapp.com."
        );
      }
    } catch (err) {
      setError(
        "Operation failed. Please try again with fewer cameras. If you continue to experience this error, please contact team@thespotlightapp.com."
      );
    }
  };

  return (
    <ModalStep
      title={"ATTACH/TRANSFER CAMERAS"}
      subtitle={
        isWorking && !error
          ? `Transfer in progress... This may take several minutes, please do not close the window.`
          : null
      }
    >
      {isWorking && !error ? (
        <ProgressBar />
      ) : (
        <Typography variant={"h6"} color="error">
          {error}
        </Typography>
      )}
    </ModalStep>
  );
};

