import React, { useState } from 'react';
import { IconButton, TableCell, TableRow, Typography } from '@material-ui/core';

import ExpandMore from '@material-ui/icons/ExpandMore';
import { Delete, ExpandLess } from '@material-ui/icons';
import { CameraDetailsCollapse } from '../camera-details-collapse';
import { useStyles } from './useStyles';

export default function CameraRow({
  camera,

  isLoading,
  setIsPendingDelete,
  organizationId,
}) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  return (
    <React.Fragment>
      <TableRow key={camera.cameraId} className={classes.bodyRow}>
        <TableCell className={classes.cameraNameCell}>
          <Typography component="h3">{camera.camera.cameraName}</Typography>
          <Typography component="p" variant="body2" className={classes.imei}>
            IMEI {camera.camera.imei}
          </Typography>
        </TableCell>
        <TableCell className={classes.collectionsTitleCell}>
          <Typography className={classes.collections}>
            {camera.camera.cameraCollectionName || 'No Collection'}
          </Typography>
        </TableCell>
        <TableCell className={classes.deleteButtonCell} style={{ padding: 0 }}>
          <IconButton
            disabled={isLoading}
            onClick={() => setIsPendingDelete(camera)}
          >
            <Delete />
          </IconButton>
        </TableCell>
        <TableCell className={classes.collapseButtonCell}>
          <IconButton onClick={() => setOpen(!open)} disabled={isLoading}>
            {open ? <ExpandMore /> : <ExpandLess />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <CameraDetailsCollapse
          camera={camera}
          open={open}
          organizationId={organizationId}
        />
      </TableRow>
    </React.Fragment>
  );
}

