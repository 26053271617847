import { Endpoints } from './api/lib/endpoints';

const awsExports = {
  Auth: {
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-east-1_3mcfyHFH0',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '4dg0nfrju0curo3oqb3h117iop',

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: 'iscstaging.auth.us-east-1.amazoncognito.com',
      scope: [
        'openid',
        'profile',
        'email',
        'phone',
        'https://staging-admin.thespotlightapp.com/api-access',
      ],
    },
  },
  API: {
    endpoints: [
      {
        name: Endpoints.ISCPortalAPI,
        endpoint: 'https://staging-admin-api.thespotlightapp.com',
      },
      {
        name: Endpoints.ISCPortalLegacy,
        endpoint: 'https://iscdev-app.cando.com',
      },
      {
        name: Endpoints.ISCPortalMock,
        endpoint:
          'https://virtserver.swaggerhub.com/HansonInc/ISC-Portal-API/1.0.0-beta-007',
      },
    ],
  },
};

export default awsExports;
