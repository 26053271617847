import React from 'react';
import fromNow from 'from-now';
import {
  Grid,
  Typography,
  ListItem,
  ListItemText,
  Hidden,
  IconButton,
  Button,
} from '@material-ui/core';
import { CollectionCameraDetailSearchGetResponseDataCameras } from '../../api/isc-api';
import { StatusIcons } from './status-icons';
import { Image } from '../image';
import { useStyles } from './useStyles';
import { OpenInBrowser, OpenInNew } from '@material-ui/icons';
import { Link } from 'react-router-dom';
type CameraListItemProps = {
  camera: CollectionCameraDetailSearchGetResponseDataCameras;
};

const CameraListItem = ({ camera }: CameraListItemProps) => {
  const classes = useStyles();
  const CAMERA_API_BASE = process.env.PUBLIC_PORTAL_URL;
  return (
    <ListItem className={classes.collectionListItem}>
      {/* <a
        target={'_blank'}
        href={`${CAMERA_API_BASE}/camera/${camera.camera.cameraId}`}
        className={classes.collectionLink}
      > */}
      <ListItemText>
        <Grid container spacing={3} className={classes.cameraCard}>
          <Hidden xsDown>
            <Grid item sm={2}>
              <Image src={camera?.cameraThumbnail?.url} alt="Camera Snapshot" />
            </Grid>
          </Hidden>
          <Grid item xs={10} sm={8} md={5}>
            <Typography className={classes.cameraName}>
              {camera.camera.cameraName}
            </Typography>
            <Typography className={classes.cameraIMEI}>
              IMEI {camera.camera.imei}
            </Typography>
            <Typography className={classes.cameraThumbnailDate}>
              {camera.cameraThumbnail?.date
                ? fromNow(camera.cameraThumbnail.date.dateTimeLocal)
                : 'No recent images'}
            </Typography>
          </Grid>
          <Hidden mdUp>
            <Grid item xs={2}>
              <a
                target={'_blank'}
                href={`${CAMERA_API_BASE}/camera/${camera.camera.cameraId}/${camera?.organizationDetails?.organizationId}`}
                className={classes.collectionLink}
              >
                <IconButton>
                  <OpenInNew />
                </IconButton>
              </a>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={3}>
            <>
              <Link
                to={`/organization/${camera?.organizationDetails?.organizationId}`}
                className={classes.organizationLink}
              >
                <Typography>
                  {camera?.organizationDetails?.organizationName}
                </Typography>
              </Link>
              {camera.operationStatus ? (
                <StatusIcons camera={camera} />
              ) : (
                <Typography className={classes.cameraThumbnailDate}>
                  Camera status unknown
                </Typography>
              )}
            </>
          </Grid>
          <Hidden smDown>
            <Grid item sm={2} className={classes.justifyRight}>
              <a
                target={'_blank'}
                href={`${CAMERA_API_BASE}/camera/${camera.camera.cameraId}/${camera?.organizationDetails?.organizationId}`}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  size="small"
                  variant="outlined"
                  disableElevation
                  startIcon={<OpenInNew />}
                >
                  Details
                </Button>
              </a>
            </Grid>
          </Hidden>
        </Grid>
      </ListItemText>
      {/* </a> */}
    </ListItem>
  );
};

export default CameraListItem;
