import React, { useCallback, useState } from 'react';
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { Clear, Search } from '@material-ui/icons';
import { debounce } from 'ts-debounce';

type DashboardSearchBoxProps = {
  value: string;
  label?: string;
  placeholderText?: string;
  className?: string;
  onFilterChange: (filter: string) => void;
};

export const SearchBox = ({
  value,
  label,
  placeholderText,
  className = undefined,

  onFilterChange,
}: DashboardSearchBoxProps) => {
  const [searchValue, setSearchValue] = useState(value);

  const debouncedUpdate = useCallback(
    debounce((input: HTMLInputElement) => {
      onFilterChange(input.value);
    }, 500),
    []
  );

  const handleValueChange = (ev: KeyboardEvent) => {
    setSearchValue((ev.target as HTMLInputElement).value);
    debouncedUpdate(ev.target as HTMLInputElement);
  };

  const handleClear = () => {
    setSearchValue('');
    onFilterChange('');
  };

  return (
    <FormControl fullWidth={true}>
      <TextField
        label={label || null}
        placeholder={placeholderText}
        id="searchField"
        type="text"
        fullWidth={true}
        value={searchValue}
        className={className}
        onChange={(ev: any) => {
          handleValueChange(ev);
        }}
        variant={'standard'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {value ? (
                <IconButton onClick={handleClear}>
                  <Clear />
                </IconButton>
              ) : (
                <Search />
              )}
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};

