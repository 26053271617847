import {
  Card,
  CardActions,
  CardHeader,
  Grid,
  IconButton,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react';
import { useHistory } from 'react-router';

import { useStyles } from './useStyles';
export const OrganizationListItem = ({ organization }) => {
  const classes = useStyles();
  const history = useHistory();
  const handleClick = id => {
    history.push(`/organization/${id}`);
  };
  return (
    <Grid key={organization.id} item xs={12} sm={6} md={6} lg={6} xl={6}>
      <Card
        className={classes.organizationCard}
        onClick={() => handleClick(organization.organizationId)}
      >
        <CardHeader
          className={classes.cardHeader}
          avatar={' '}
          title={organization.organizationName}
          subheader={'Organization Account'}
        />
        <CardActions disableSpacing>
          <IconButton onClick={() => handleClick(organization.id)}>
            <Edit />
          </IconButton>
        </CardActions>
      </Card>
    </Grid>
  );
};

