import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Paper,
} from '@material-ui/core';

import React, { useState } from 'react';
import { PageHeader } from '../../components/page-header';
import { useStyles } from './useStyles';
import { formatDate } from '../../formats';
import { Add, Delete, ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';
import { NewFirmwareVersionModal } from '../../components/add-new-firmware-version/NewFirmwareVersionModal';
import { useApi, useApiUpdate } from '../../hooks';
import { FirmwareAPI } from '../../api/firmware';
import { ProgressBar } from '../../components/progress-bar';
import { IscFirmwareItem, LTEFirmwareItem } from '../../api/isc-api';
import { ConfirmDialogModal } from '../../components/confirm-dialog-modal';
import { AlertSnack } from '../../components/alert-snack';

type FirmwarePageProps = {};

export const FirmwarePage = ({}: FirmwarePageProps) => {
  const classes = useStyles();
  const [firmware, , isLoading] = useApi(() => FirmwareAPI.get());
  const [showAlert, setShowAlert] = useState(null);
  const [doDeleteFirmware] = useApiUpdate(
    (id: string) => FirmwareAPI.delete(id),
    result => {
      return result;
    }
  );
  const [isPendingAddingFirmware, setIsPendingAddingFirmware] = useState(false);
  const [isPendingDeleteFirmware, setIsPendingDeleteFirmware] = useState(false);

  let currentISCFirmware: IscFirmwareItem;
  let currentLTEFirmware: LTEFirmwareItem;
  let iscFirmwareList: IscFirmwareItem[];
  if (firmware) {
    currentISCFirmware = firmware.iscFirmware[0];
    currentLTEFirmware = firmware.lteFirmware[0];
    iscFirmwareList = [...firmware.iscFirmware];
    iscFirmwareList.splice(0, 1);
  }

  const handleNewVersionClick = () => {
    setIsPendingAddingFirmware(true);
  };
  const handleCancel = () => {
    setIsPendingAddingFirmware(false);
  };
  const handleSave = () => {
    setIsPendingAddingFirmware(false);
    window.location.reload();
  };
  const handleDeleteFirmware = async () => {
    setIsPendingDeleteFirmware(false);
    try {
      await doDeleteFirmware(currentISCFirmware.firmwareVersionId).then(
        response => {
          window.location.reload();
        }
      );
    } catch (error) {
      setShowAlert({
        type: 'error',
        message: 'There was a problem deleting firwmare version',
      });
    }
  };
  return (
    <>
      <PageHeader title={'Firmware'} />
      {isLoading ? (
        <ProgressBar />
      ) : (
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6} md={6}>
            <>
              <div className={classes.firmwarePageHeader}>
                <h2 className={classes.firmwareHeader}>
                  Current ISC Firmware Version
                </h2>
                <Button
                  color="secondary"
                  variant="outlined"
                  className={classes.firmwareButton}
                  onClick={handleNewVersionClick}
                >
                  <Add className={classes.buttonIcon} />
                  New Version
                </Button>
              </div>

              <Paper className={classes.firmwarePaperContainer}>
                {!!currentISCFirmware ? (
                  <>
                    {' '}
                    <h2 className={classes.firmwareVersionNumber}>
                      {currentISCFirmware.versionNumber}
                    </h2>
                    <p>
                      Uploaded{' '}
                      <strong>
                        {formatDate(currentISCFirmware.uploadDate)}
                      </strong>{' '}
                      by <strong>{currentISCFirmware.uploadUser.name}</strong>
                    </p>
                    <p className={classes.checksum}>
                      <strong> {currentISCFirmware.filesize}</strong>, checksum{' '}
                      {currentISCFirmware.checksum}
                    </p>
                    <p className={classes.checksum}>
                      <strong> Release Notes: </strong>
                      <br /> {currentISCFirmware.releaseNotes}
                    </p>
                    <div className={classes.currentFirmwareButtonRow}>
                      <Button
                        color="secondary"
                        variant="outlined"
                        className={classes.firmwareButton}
                        onClick={() => setIsPendingDeleteFirmware(true)}
                      >
                        <Delete className={classes.buttonIcon} />
                        DELETE THIS VERSION
                      </Button>
                    </div>
                  </>
                ) : (
                  <div>No ISC Firmware Versions</div>
                )}
              </Paper>
            </>
            {/* LTE FIRMWARE VERSIONS REMOVED UNTIL FURTHER NOTICE 3/20/23 */}
            {/* <>
              <div className={classes.firmwarePageHeader}>
                <h2 className={classes.firmwareHeader}>
                  Current LTE Firmware Version
                </h2>
              </div>
              <Paper className={classes.firmwarePaperContainer}>
                {!!currentLTEFirmware ? (
                  <>
                    <h2 className={classes.firmwareVersionNumber}>
                      {currentLTEFirmware.versionNumber} LTE
                    </h2>

                    <p className={classes.checksum}>
                      <strong> Release Notes: </strong>
                      <br /> {currentLTEFirmware.releaseNotes}
                    </p>
                  </>
                ) : (
                  <div> No LTE Firmware Versions </div>
                )}
              </Paper>
            </> */}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <div className={classes.firmwarePageHeader}>
              <h2 className={classes.firmwareHeader}>Previous Versions</h2>
            </div>
            <Paper
              className={clsx(
                classes.firmwarePaperContainer,
                classes.firmwarePreviousVersionsContainer
              )}
            >
              {iscFirmwareList.map(firmware => (
                <Accordion className={classes.firmwareAccordian}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls={`${firmware.firmwareVersionId}-content`}
                    id={`${firmware.firmwareVersionId}-header`}
                  >
                    <div>
                      <p className={classes.firmwareAccordianHeader}>
                        <strong>{firmware.versionNumber} - </strong> Uploaded{' '}
                        {formatDate(firmware.uploadDate)} by{' '}
                        <strong>{firmware.uploadUser.name}</strong>
                      </p>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={classes.firmwareAccordianDetail}>
                      <p className={classes.checksum}>
                        <strong> {firmware.filesize}</strong>, checksum{' '}
                        {firmware.checksum}
                      </p>
                      <p className={classes.checksum}>
                        <strong> Release Notes: </strong>
                        <br /> {firmware.releaseNotes}
                      </p>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Paper>
          </Grid>
        </Grid>
      )}
      <NewFirmwareVersionModal
        onSaved={handleSave}
        onCancel={handleCancel}
        open={isPendingAddingFirmware}
      />
      <ConfirmDialogModal
        open={isPendingDeleteFirmware}
        value={isPendingDeleteFirmware}
        handleConfirm={handleDeleteFirmware}
        handleClose={() => setIsPendingDeleteFirmware(false)}
        dialogTitle="Delete Firmware"
        message="Are you sure you want to delete this firmware version? Any cameras currently running this firmware version will continue to use it until a newer firmware update is uploaded."
        confirmText="Confirm"
      />
      {Boolean(showAlert) && (
        <AlertSnack
          message={showAlert.message}
          type={showAlert.type}
          onClose={() => {
            console.log(showAlert);
          }}
        />
      )}
    </>
  );
};

