import { API } from './lib/api';
import {
  OrganizationAddPostBody,
  OrganizationDeleteResponse,
  OrganizationDetailBasic,
  OrganizationsListGetResponseData,
} from './isc-api';

export const OrganizationsApi = {
  /**
   * @description Returns a list of all organizations in alphabetical order, 20 items per page **Wireframe references** - [[AP2] Organizations](https://yqyoqc.axshare.com/#g=1&p=_ap2__organizations)
   *
   * @tags Organizations
   * @summary Get List Of Organizations
   * @request GET:/organizations/
   * @secure
   */
  get: () => API.get<OrganizationsListGetResponseData>('/organizations'),
  /**
   * @description Creates a new organization Setting the Organization Name, Organization Admin ID, user, default features. If the endpoint returns no errors, it should return the newly-created Organization ID **Wireframe references** - [[AP2.2] Create Organization](https://yqyoqc.axshare.com/#g=1&p=_ap2_2__create_organization)
   *
   * @tags Organizations
   * @summary Create A New Organization
   * @request POST:/organizations/
   * @secure
   */
  create: (data: OrganizationAddPostBody) => {
    return API.post<OrganizationDetailBasic>('/organizations', {
      body: data,
    });
  },
  /**
   * @description Sets the organization's state to deleted and detaches all users, groups, collections, and cameras from it. **Wireframe references** - [[AP2] Organizations](https://yqyoqc.axshare.com/#g=1&p=_ap2__organizations)
   *
   * @tags Organizations
   * @name OrganizationsDelete
   * @summary Delete Organization
   * @request DELETE:/organizations/{organizationId}
   * @secure
   */
  delete: (id: string) => {
    return API.del<OrganizationDeleteResponse>(`/organizations/${id}`, {});
  },
};
