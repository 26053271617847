import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  organizationCard: {
    display: 'flex',
    width: '100%',
    justifyContent: 'between',
    transition: 'background-color 0.33s ease',

    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  cardHeader: {
    flexGrow: 1,
    '& .MuiCardHeader-title': {
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiCardHeader-content': {
        overflow: 'hidden',
      },
      '& .MuiTypography-root': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
}));

