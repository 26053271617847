import { API } from './lib/api';

import { handleErrorResponse } from './lib/helpers';
import {
  OrganizationAttachCamerasPut,
  OrganizationAttachCamerasPutResponse,
  OrganizationDetailBasic,
  OrganizationDetailPage,
  OrganizationDetailPagePut,
  OrganizationsDetailsGetResponse,
  OrganizationsDetailsPutResponse,
  RemoveCameraFromOrganization,
} from './isc-api';

export const OrganizationDetailsAPI = {
  /**
   * @description Organization details: Returns in a single calll the organization `name`,  `user` list, organization `camera` list, organization `features` **Wireframe references** - [[AP2.1] Organization Details](https://yqyoqc.axshare.com/#g=1&p=_ap2_1__organization_details)
   * @tags Organization Details
   * @summary Get Organization Details
   * @request GET:/organizations/{organizationId}/details
   * @secure
   */
  getOrganization: (organizationId: string) => {
    return API.get<OrganizationsDetailsGetResponse>(
      `/organizations/${organizationId}/details`,
      {}
    );
  },
  /**
   * @description Used to update organization users and features. Updating the cameras attached to an organization is a separate endpoint (see PUT /organizations/{organizationId}/attach/cameras). Attach array of users with the `canManageOrganization` permission to Organization so they can log in and manage organization **on the backend users should be given the organization admin role** **Wireframe references** - [[AP2.1.] Organization Details](https://yqyoqc.axshare.com/#g=1&p=_ap2_1__organization_details) - [[AP2.1.1] User Details](https://yqyoqc.axshare.com/#g=1&p=_ap2_1_1__add_a_user) - [[AP2.1.1] Add a User](https://yqyoqc.axshare.com/#g=1&p=_ap2_1_1__add_a_user) - [[AP2.1.2] Attach a Camera](https://yqyoqc.axshare.com/#g=1&p=_ap2_1_2__attach_a_camera)
   * @tags Organization Details
   * @summary Update Organization Details
   * @request PUT:/organizations/{organizationId}/details
   * @secure
   */
  updateOrganization: (
    organizationId: string,
    data: OrganizationDetailPagePut
  ) => {
    return API.put<OrganizationsDetailsPutResponse>(
      `/organizations/${organizationId}/details/`,
      {
        body: data,
      }
    );
  },
  /**
   * @description Can be used by an ISC admin to move a camera from one organization to another. This may be done for testing purposes, or because ownership of a camera is being physically transferred. Behind the scenes, this endpoint will take the following actions: - change the organizationId/accountId on camera itself - update visibility filters - update account/organization-related fields and data from the camera, including: - placementId - collectionId (cleanup collection visibility) - lastMediaId - sort order - location - subscriptionId - lastStatusId - lastSeen (I think it would be fair that we need a new last seen value to be sure that camera moved successfully) - detach all LPR profiles which are attached to another organization - remove this camera from pinned cameras for the old organization - removes any settings overrides applied to this camera in the portal Any media and alerts generated by the camera by the old organization will not be accessible to the camera's new owners. The camera's old owners will lose the ability to access the camera's details, settings, media, and alerts, but these will remain in the database, attached to the old organization, to support future search capabilities. This change will require a change to how alerts are stored, to associate alerts with an organization (media is already associated with an organization in this way). **Wireframe references** - [[AP2.1.] Organization Details](https://yqyoqc.axshare.com/#g=1&p=_ap2_1__organization_details) - [[AP2.1.2] Attach a Camera](https://yqyoqc.axshare.com/#g=1&p=_ap2_1_2__attach_a_camera)
   * @tags Attach Camera
   * @summary Attach / Transfer Cameras to Organization
   * @request PUT:/organizations/{organizationId}/attach/cameras
   * @secure
   */
  attachCamera: (
    organizationId: string,
    data: OrganizationAttachCamerasPut
  ) => {
    return API.put<OrganizationAttachCamerasPutResponse>(
      `/organizations/${organizationId}/attach/cameras`,
      {
        body: data,
      }
    );
  },

  deleteCamera: async (
    organizationId: string,
    cameraId: string
  ): Promise<RemoveCameraFromOrganization> => {
    return await API.del(
      `/organizations/${organizationId}/cameras/${cameraId}`
    );
  },
};

