import React, { useCallback, useState } from 'react';
import {
  FormControl,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Clear, Search } from '@material-ui/icons';
import { debounce } from 'ts-debounce';
import { OrganizationsDashboardSearchTypes } from '../pages/dashboard';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from 'clsx';
const useStyles = makeStyles(theme => ({
  formControl: {
    display: 'flex',
    backgrounColor: 'inherit',
    verticalAlign: 'bottom',
    flexDirection: 'row',
    justifyItems: 'center',
		minWidth: "200px",
		[theme.breakpoints.down('xs')]: {
			flexWrap: 'wrap',
			marginBottom: '8px'
    },
  },
  light: {
    '& *, & *:hover, & *:active, & *:focus, &:before, &:after, & .MuiInput-underline:before, & .MuiInput-underline:after, & #searchField-label':
      {
        color: theme.palette.common.white,
        borderColor: theme.palette.common.white,
      },
  },
  dashboardSelect: {
    paddingRight: '0px',
  },
	icon: {
 // We use a position absolute over a flexbox in order to forward the pointer events
    // to the input and to support wrapping tags..
    position: 'absolute',
    right: 0,
    top: 'calc(50% - 12px)', // Center vertically
    pointerEvents: 'none', // Don't block pointer events on the select under the icon.
    color: theme.palette.action.active,
    '&$disabled': {
      color: theme.palette.action.disabled,
    },
	},
  inputSearchBox: {
    '& #searchField-label': {
      paddingLeft: '12px',
      ' & .MuiInputLabel-formControl ': {
        transform: 'translate(0, 20px) scale(1)',
      },
    },
  },
}));

type DashboardSearchBoxProps = {
  value: string;
  placeholderText?: string;
  onFilterChange: (filter: string, searchType: string) => void;
  className?: string;
};

export const DashboardSearchBox = (props: DashboardSearchBoxProps) => {
  const classes = useStyles();
  const [value, setValue] = useState(props.value);
  const [searchType, setSearchType] = useState(
    OrganizationsDashboardSearchTypes.Organizations
  );
  const debouncedUpdate = useCallback(
    debounce((input: HTMLInputElement, type: string) => {
      props.onFilterChange(input.value, type);
    }, 500),
    []
  );

  const handleValueChange = (ev: KeyboardEvent, type: string) => {
    setValue((ev.target as HTMLInputElement).value);
    debouncedUpdate(ev.target as HTMLInputElement, type);
  };

  const handleReset = (type?: OrganizationsDashboardSearchTypes) => {
    setValue('');
    if (!!type) {
      props.onFilterChange('', type);
    }
  };
  const handleClear = () => {
    setValue('');

    props.onFilterChange('', searchType);
  };
  const handleSearchTypeChange = (event: any) => {
    if (!!event.target.value) {
      setSearchType(event.target.value);
      handleReset(event.target.value);
    }
  };


  return (
    <div className={classes.formControl}>
      <FormControl className={classes.formControl}>
        <Select
          labelId="dashboard-search-type-label"
          id="dashboard-search-type"
          value={searchType}
          fullWidth
          variant={'standard'}
					IconComponent={ExpandMoreIcon}
          className={clsx(classes.light, classes.dashboardSelect)}
          onChange={handleSearchTypeChange}
        >
          <MenuItem value={OrganizationsDashboardSearchTypes.Organizations}>
            Organizations
          </MenuItem>
          <MenuItem value={OrganizationsDashboardSearchTypes.Cameras}>
            Cameras
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <TextField
          placeholder={
            searchType === OrganizationsDashboardSearchTypes.Organizations
              ? 'Organization Name'
              : 'Camera Name or IMEI'
          }
          id="searchField"
          type="text"
          fullWidth
          variant={'standard'}
          value={value}
          className={clsx(classes.light, classes.inputSearchBox)}
          onChange={(ev: any) => {
            handleValueChange(ev, searchType);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {value ? (
                  <IconButton onClick={handleClear}>
                    <Clear />
                  </IconButton>
                ) : (
                  <Search />
                )}
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </div>
  );
};

