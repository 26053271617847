import React, { useEffect } from 'react';

import Button from '@material-ui/core/Button';
import { ModalStep } from '../add-organization/step';

import { Typography } from '@material-ui/core';
import { ProgressBar } from '../progress-bar';

import { OrganizationDetailPagePut, UserDetails } from '../../api/isc-api';

import { UpdateBody } from '../../pages/organization-details';

type LoadingAddNewOrganizationProps = {
  onSaved: (users: UserDetails[]) => void;
  updateObject: OrganizationDetailPagePut;
  organizationId: string;
  updateOrganization: (
    value: UpdateBody
  ) => Promise<void | OrganizationDetailPagePut>;
};

export const LoadingAddUserToOrganization = (
  props: LoadingAddNewOrganizationProps
) => {
  const [error, setError] = React.useState<string>(null);
  const [isSaving, setIsSaving] = React.useState(false);

  useEffect(() => {
    const newOrg = {
      ...props.updateObject,
      users: props.updateObject.users.map(user => ({
        userId: user.userId,
      })),
    };
    saveOrganization(newOrg);
  }, []);

  const saveOrganization = async (
    organizationObject: OrganizationDetailPagePut
  ) => {
    setIsSaving(true);
    try {
      const result = await props.updateOrganization({
        id: props.organizationId,
        body: organizationObject,
      });
      if (result) {
        setIsSaving(false);
        props.onSaved(result?.users);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <ModalStep
      title={'Adding Users to Organization'}
      subtitle={'Please wait while the users are added'}
      actions={
        <React.Fragment>
          <Button type="submit" color="primary" disabled>
            {'Saving'}
          </Button>
        </React.Fragment>
      }
    >
      {isSaving && !error ? (
        <ProgressBar />
      ) : (
        <Typography variant={'h6'} color="error">
          {error}
        </Typography>
      )}
    </ModalStep>
  );
};

