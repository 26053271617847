import { API } from './lib/api';
import {
  DeleteFirmwareResponse,
  FirmwarePatch,
  FirmwarePost,
  GetFirmwareResponse,
  GetFirmwareVersionResponse,
  IscFirmwareItem,
  PatchFirmwareResponse,
  PostFirmwareResponseData,
} from './isc-api';

export const FirmwareAPI = {
  /**
   * @description Firmware results: ISC firmware array returns current firmware version and up to 10 previous versions (11 total). ISC firmware item fields: version number, upload date, upload user, checksum, file size, release notes. LTE firmware array returns latest version only. LTE firmware item fields: version number, version name description, release notes **Wireframe references** - [[AP7] Firmware](https://yqyoqc.axshare.com/#g=1&p=_ap7__firmware)
   *
   * @tags Firmware
   * @name FirmwareList
   * @summary Get Firmare List
   * @request GET:/firmware/
   * @secure
   */

  get: async () => {
    return await API.get<GetFirmwareResponse>(`/firmware`);
  },
  getFirmware: async (firmwareVersionId: string) => {
    return await API.get<IscFirmwareItem>(`/firmware/${firmwareVersionId}`);
  },
  /**
   * @description **SHIFT NOTES** post body will have bin file and release notes **Wireframe references** - [[AP7] Firmware](https://yqyoqc.axshare.com/#g=1&p=_ap7__firmware)
   *
   * @tags Firmware
   * @name FirmwareCreate
   * @summary Add New Firmware
   * @request POST:/firmware/
   * @secure
   */
  create: async (data: FirmwarePost) => {
    return await API.post<PostFirmwareResponseData>(`/firmware`, {
      body: data,
    });
  },
  patchFirmwareStatus: async (
    firmwareVersionId: string,
    data: FirmwarePatch
  ) => {
    return await API.patch<PatchFirmwareResponse>(
      `/firmware/${firmwareVersionId}`,
      {
        body: data,
      }
    );
  },
  postFile: async (uploadUrl: string, file: any): Promise<any> => {
    const myHeaders = new Headers({
      'Content-Type': 'application/octet-stream',
    });
    return await fetch(uploadUrl, {
      method: 'PUT',
      headers: myHeaders,
      body: file,
    });
  },
  delete: async (firmwareVersionId: string) => {
    return await API.del<DeleteFirmwareResponse>(
      `/firmware/${firmwareVersionId}`,
      {}
    );
  },
};

