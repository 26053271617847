import React from 'react';
import Button from '@material-ui/core/Button';
import { ModalStep } from '../add-organization/step';
import { CollectionCameraDetailSearchGetResponseDataCameras } from '../../api/isc-api';
import {Grid, makeStyles} from '@material-ui/core';
import { AttachCamerasTable } from './attach-cameras-table';

type ConfirmAddCameraProps = {
  onCancel: () => void;
  onNext: (
    cameras: CollectionCameraDetailSearchGetResponseDataCameras[]
  ) => void;
  cameras: CollectionCameraDetailSearchGetResponseDataCameras[];
  submitButtonLabel?: string;
  organizationName?: string;
};

const useStyles = makeStyles(theme => ({
  attachCamerasTableWrapper: {
    [theme.breakpoints.down('sm')]: {
      overflow: 'auto',
      maxHeight: '300px',
    },
  },
}));


export const ConfirmAddCameras = (props: ConfirmAddCameraProps) => {
  const selectedCameras = props.cameras;
  const classes = useStyles();

  const handleNext = () => {
    props.onNext(selectedCameras);
  };

  return (
    <ModalStep
      title={'ATTACH/TRANSFER CAMERAS'}
      subtitle={`Please confirm the list of cameras that will be attached to the organization ${props.organizationName}. If cameras were previously attached to a different organization, any media and alerts captured will not be visible to the new owners. Any groups, collections, and recognition profiles associated with this camera will be removed and camera settings will be reset to defaults.`}
      onCancel={props.onCancel}
      actions={
        <React.Fragment>
          <Button onClick={props.onCancel} size="small">
            Cancel
          </Button>
          <Button type="submit" color="primary" size="small">
            {props.submitButtonLabel || 'Confirm'}
          </Button>
        </React.Fragment>
      }
      onSubmit={handleNext}
    >
      <Grid container xs={12}>
        <Grid item xs={12} className={classes.attachCamerasTableWrapper}>
          <AttachCamerasTable
            cameras={props.cameras}
            headers={['IMEI', 'Camera Name', 'Attached To Organization']}
          />
        </Grid>
      </Grid>
    </ModalStep>
  );
};

