import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { ModalStep } from '../add-organization/step';
import { FormHelperText } from '@material-ui/core';
import { useStyles } from './useStyles';

type SelectFirmwareFileProps = {
  onCancel: () => void;
  onNext: (file: any) => void;
  title: string;
  subtitle: string;
};

export const SelectFirmwareFile = (props: SelectFirmwareFileProps) => {
  const [error, setError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const classes = useStyles();

  const handleFileInput = e => {
    const file = e.target.files[0];
    setSelectedFile(file);
    const isValid = validateFile(file.name);
    setError(
      isValid
        ? null
        : 'Invalid file selected. Firmware file must have a .bin extension.'
    );
  };

  const validateFile = file => {
    var x = /^[^.]+.bin$/;
    return x.test(file);
  };
  const handleNext = () => {
    if (!error) {
      props.onNext(selectedFile);
    }
  };

  return (
    <ModalStep
      title={props.title}
      subtitle={props.subtitle}
      onCancel={props.onCancel}
      actions={
        <React.Fragment>
          <Button onClick={props.onCancel} size="small" tabIndex="-1">
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            size="small"
            disabled={!selectedFile || !!error}
          >
            {'Next'}
          </Button>
        </React.Fragment>
      }
      onSubmit={handleNext}
    >
      <div>
        <input type="file" onChange={handleFileInput} />
        {!!error && (
          <FormHelperText className={classes.helperText}>
            {error}
          </FormHelperText>
        )}
      </div>
    </ModalStep>
  );
};

